import React from 'react';
import PropTypes from 'prop-types';

import Tab from '../Tab';
import styles from './Tabs.module.scss';

const TabProp = (props, propName, componentName) => {
	const prop = props[propName];
	if (prop.type !== Tab && propName !== 'children') {
		console.error(`'${componentName}' only accepts 'Tab' as children.`);
		return false;
	} else return true;
};

const Tabs = ({ reverse = false, renderLabel, renderContent, children }) => {
	if (reverse) {
		children = children.reverse();
	}

	return (
		<div className="tabs">
			<div className={styles.tabsBar}>{children.map((el, index) => renderLabel(el, index))}</div>
			<div>{renderContent(children)}</div>
		</div>
	);
};

Tabs.propTypes = {
	children: PropTypes.oneOfType([TabProp, PropTypes.arrayOf(TabProp)]),
	reverse: PropTypes.bool
};

export default Tabs;
