import React, { useState } from 'react';
import useSWR from 'swr';

import { Cluster, Button, Loader } from '@nearst/ui';
import { listPlans } from '@services/localAds';

import { TurnOffLIAModal, ChangeLIAModal } from '../../Modals';
import { updateLiaPlan } from '../utils';

import styles from './BudgetSlider.module.scss';

const Actions = ({ shop, newBudget, oldBudget, reset, formattedBudget }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [turnOffLiaModalOpen, setTurnOffLiaModalOpen] = useState(false);
	const [changeModalOpen, setchangeModalOpen] = useState(false);
	const { mutate: revalidate } = useSWR(`${shop.id}-upcoming-plan`, () => listPlans({ id: shop.id, status: 'upcoming' }));

	const handleSubmit = async () => {
		setLoading(true);
		setError(false);
		try {
			await updateLiaPlan({ shop, budget: newBudget, revalidate, origin: 'instantAdsOverview' });
			reset(newBudget);
		} catch (e) {
			console.error(e);
			setError(true);
		} finally {
			setLoading(false);
		}
	};

	const handleTurnLiaOff = async () => {
		window.open('https://share.hsforms.com/1tv3mGGbMRni-cPRoAMFPpw16uos', '_blank');
		await handleSubmit();
	};

	return (
		<>
			<Cluster>
				<Button
					primary
					disabled={loading || error}
					data-cy={`local-ads-confirm`}
					className={styles.button}
					onClick={() => {
						if (!shop.subscriptionLiaBudget && newBudget === 0) {
							setTurnOffLiaModalOpen(true);
						} else {
							setchangeModalOpen(true);
						}
					}}
				>
					Confirm
				</Button>
				<Button data-cy={`local-ads-clear`} className={`${styles.button} ${styles.cancel}`} onClick={() => reset(oldBudget)}>
					Clear
				</Button>
				{loading && <Loader size={20} />}
			</Cluster>
			{error ? (
				<div className={`${styles.fullWidth} ${styles.errorBlurb}`} data-cy={`local-ads-error-message`}>
					<p className={styles.error}>Sorry - something went wrong with this request.</p>
					<p>
						Please contact your Customer Success representative{' '}
						{shop.customerSuccessContact ? (
							<a className={styles.link} href={`mailto:${shop.customerSuccessContact.email}`}>
								{shop.customerSuccessContact.name}{' '}
							</a>
						) : (
							''
						)}
						to resolve the issue.
					</p>
				</div>
			) : null}
			<ChangeLIAModal
				shop={shop}
				budget={formattedBudget}
				show={changeModalOpen}
				close={() => setchangeModalOpen(false)}
				choosePlan={handleSubmit}
			/>
			<TurnOffLIAModal show={turnOffLiaModalOpen} close={() => setTurnOffLiaModalOpen(false)} cancelPlan={handleTurnLiaOff} />
		</>
	);
};

export default Actions;
