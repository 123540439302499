import React from 'react';

import styles from './Switcher.module.scss';

const Switcher = ({ children, threshold = 0, space = '1rem', className = '', ...restOfProps }) => {
	return (
		<div style={{ '--threshold': threshold, '--space': space }} className={`${styles.switcher} ${className}`} {...restOfProps}>
			<div>{children}</div>
		</div>
	);
};

export default Switcher;
