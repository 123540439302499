/**
 * Returns a common prefix between multiple strings of text.
 * Only ever returns whole words (i.e. separated by spaces).
 *
 * Example: ['a b c', 'a b d'] => 'a b'
 *
 * @param {string[]} strings
 * @returns {string}
 */
const findCommonPrefix = (strings) => {
	if (strings.length < 2) return '';

	let prefix = strings[0].split(' ');
	for (let i = 0; i < strings.length; i++) {
		const str = strings[i].split(' ');
		while (str.join(' ').indexOf(prefix.join(' ')) !== 0) {
			prefix.pop();
			if (!prefix.length) return '';
		}
		if (prefix.join(' ') === strings[i]) prefix = str.slice(0, -1);
	}

	return prefix.join(' ');
};

export default findCommonPrefix;
