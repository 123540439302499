import React from 'react';
import { Protect } from '@clerk/clerk-react';
import { format } from 'date-fns';

import { Card, Pill, Page, CheckboxInput } from '@nearst/ui';
import Table from '@components/Table';

import UserAvatar from '../../components/UserAvatar';
import TeamInviteModal from '../../components/TeamInviteModal';
import RoleSelect from '../../components/RoleSelect';
import AccessDenied from '../../../errors/AccessDenied';
import UserActions from '../../components/UserActions';
import { useOrganisationUsers } from '@services/auth/organisation';

import styles from './Team.module.scss';
import AuthorizationTooltip from '@components/AuthorizationTooltip';
import { useCheckPermission } from '@services/auth/hooks';
import { updateEmailPreferences } from '@services/users';

const ManageTeam = () => (
	<Protect permission="org:user_permissions:read" fallback={<AccessDenied />}>
		<Page.Header>
			<h1>Manage team members</h1>
			<p>Add or remove users in your account.</p>
		</Page.Header>
		<Page.Section>
			<OrgMembers />
		</Page.Section>
	</Protect>
);

const OrgMembers = () => {
	const authorized = useCheckPermission('org:user_permissions:manage');
	const { isLoading, users, revalidate, optimisticUpdate } = useOrganisationUsers();

	return (
		<Card className={styles.table}>
			{isLoading ? (
				<Card.Loader message="Loading users..." />
			) : (
				<>
					<div className={styles.controls}>
						<Card.Title>Organisation users</Card.Title>
						<Protect permission="org:user_permissions:manage">
							<TeamInviteModal revalidate={revalidate} />
						</Protect>
					</div>
					<Table
						data={users}
						fields={[
							{
								id: 'user',
								title: 'User',
								sortFn: {
									asc: (a, b) => (a.emailAddress > b.emailAddress ? 1 : -1),
									desc: (a, b) => (b.emailAddress < a.emailAddress ? -1 : 1)
								},
								formatter: (row) => (
									<div className={styles.user}>
										<UserAvatar user={row} />
										{row.emailAddress}
									</div>
								)
							},
							{
								id: 'joined',
								title: 'Joined',
								sortFn: {
									asc: (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
									desc: (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
								},
								formatter: (row) =>
									row.invited ? (
										<Pill colour="grey">Invited</Pill>
									) : (
										<>
											<span className={styles.mobileOnly}>Joined </span>
											{format(new Date(row.createdAt), 'MMM dd, yyyy')}
										</>
									)
							},
							{
								id: 'role',
								title: 'Role',
								sortFn: {
									asc: (a, b) => (a.role > b.role ? 1 : -1),
									desc: (a, b) => (b.role < a.role ? -1 : 1)
								},
								formatter: (row) => (
									<RoleSelect
										className={styles.role}
										role={row.role}
										onChange={(role) => row.update({ role })}
										disabled={row.isMe || row.invited}
									/>
								)
							},
							{
								id: 'weekly-insights',
								title: 'Receive weekly insights',
								sortFn: {
									asc: (a, b) => (a.weeklyInsights > b.weeklyInsights ? 1 : -1),
									desc: (a, b) => (b.weeklyInsights < a.weeklyInsights ? -1 : 1)
								},
								formatter: (row) =>
									!row.invited && (
										<AuthorizationTooltip authorized={authorized}>
											<CheckboxInput
												className={styles.checkbox}
												disabled={!authorized}
												checked={row.weeklyInsights}
												onChange={async () => {
													await optimisticUpdate(
														row.userId,
														{
															weeklyInsights: !row.weeklyInsights
														},
														(userId, update) =>
															updateEmailPreferences({ userId, emailWeeklyLiaUpdate: update.weeklyInsights })
													);
												}}
											/>
										</AuthorizationTooltip>
									)
							},
							{
								title: '',
								id: 'button',
								formatter: (row) => <UserActions className={styles.actions} user={row} revalidate={revalidate} />
							}
						]}
					/>
				</>
			)}
		</Card>
	);
};

export default ManageTeam;
