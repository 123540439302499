import React from 'react';

import { classnames } from '../../utils';
import styles from './Sidebar.module.scss';

/**
 * Main navigation sidebar in the Retailers Dashboard.
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {boolean} [props.mobileExpanded]
 * @param {string} [props.className]
 * @returns {React.JSX.Element}
 */
const Sidebar = ({ children, mobileExpanded = false, className = '' }) => {
	return <nav className={classnames(styles.sidebar, mobileExpanded && styles.mobileExpanded, className)}>{children}</nav>;
};

/**
 * A menu item in the sidebar.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {string} [props.className]
 * @param {boolean} [props.active] - Show the item as active
 * @returns {React.JSX.Element}
 */
const Item = ({ children, active = false, className = '' }) => {
	return (
		<li className={classnames(className, styles.item, active && styles.activeItem)} aria-current={active ? 'page' : undefined}>
			{children}
		</li>
	);
};

/**
 * Group a top-level item and a submenu together.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {string} [props.className]
 * @param {string} [props.title] - Optional group title
 * @returns {React.JSX.Element}
 */
const Group = ({ children, title = '', className = '' }) => {
	return (
		<div className={classnames(className, styles.group)}>
			{title && <h4 className={classnames(className, styles.groupTitle)}>{title}</h4>}
			{children}
		</div>
	);
};

/**
 * Optional footer that is shown at the bottom of the sidebar.
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} [props.className]
 * @returns {React.JSX.Element}
 */
const Footer = ({ children, className = '' }) => {
	return <div className={classnames(styles.footer, className)}>{children}</div>;
};

/**
 * Wraps the sidebar and main content, to allow styling the outer layout.
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} [props.className]
 * @returns {React.JSX.Element}
 */
const Layout = ({ children, className = '' }) => {
	return <div className={classnames(styles.sidebarLayout, className)}>{children}</div>;
};

Sidebar.Item = Item;
Sidebar.Group = Group;
Sidebar.Footer = Footer;
Sidebar.Layout = Layout;

export default Sidebar;
