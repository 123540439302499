import React, { useState } from 'react';
import styles from './styles.module.css';
import { DropdownItem, DropdownMenu } from '@nearst/ui';

/**
 * @typedef {object} Measure
 * @property {string} Measure.value
 * @property {string} Measure.label
 *
 * @param {{initialMeasure: Measure, measures: Measure[], children: (measure: Measure) => React.ReactNode }} param0
 */
const Toggle = ({ initialMeasure, measures, children }) => {
	const [selectedMeasure, setSelectedMeasure] = useState(initialMeasure);

	return (
		<div className={styles.container}>
			{children(selectedMeasure)}
			<div className={styles.toggleContainer}>
				<DropdownMenu primary={false} mini placement="bottom-end" buttonText={selectedMeasure.label}>
					{measures.map((measure) => (
						<DropdownItem
							active={selectedMeasure.value === measure.value}
							key={measure.value}
							onClick={() => setSelectedMeasure(measure)}
						>
							{measure.label || measure.value}
						</DropdownItem>
					))}
				</DropdownMenu>
			</div>
		</div>
	);
};

export default Toggle;
