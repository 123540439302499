import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import styles from './Block.module.scss';

/**
 * @deprecated Use `<Page.Section>` instead where possible.
 */
const Block = ({ title, description, className = '', before, wide, loading, children, headerComponent }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.container} style={{ maxWidth: `${wide}` }}>
				{before ? before() : null}
				<div className={styles.header}>
					<h1 className={styles.title}>{title}</h1>
					{headerComponent}
				</div>
				<p className={styles.description}>{description}</p>
				{loading ? (
					<div className={styles.loadingWrapper}>
						<Loader />
					</div>
				) : (
					<div className={`${className} ${styles.content}`}>{children}</div>
				)}
			</div>
		</div>
	);
};

Block.propTypes = {
	title: PropTypes.node,
	description: PropTypes.node,
	className: PropTypes.string,
	children: PropTypes.node,
	loading: PropTypes.bool,
	before: PropTypes.func,
	wide: PropTypes.string,
	headerComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

export default Block;
