import React, { Suspense } from 'react';
import useSWR from 'swr';

import { Card, Loader } from '@nearst/ui';
import * as Stats from '@components/StatsCard';
import Table from '@components/Table';
import OrganisationStockStatus from '../OrganisationStockStatus';

import { useInsightsDate } from '@services/InsightsDateProvider';
import { useStoredQuery } from '@services/insights';
import { batchGetStock } from '@services/stock';

import styles from './ProductLocatorPopularProducts.module.scss';
import ProductThumbnailPlaceholder from '../../../../../assets/images/insights/product-thumbnail-placeholder.svg';

const PopularProductsContent = () => {
	const { data } = useStoredQuery('product-locator/popular-products', {}, { suspense: true });

	const barcodes = data.map((item) => item.barcode);
	const { data: stock } = useSWR(barcodes, () => batchGetStock(barcodes), { suspense: true });

	const mergedData = data.map((item) => {
		const stockData = stock.find((stockItem) => stockItem.barcode === item.barcode.toString());
		return { ...stockData, ...item };
	});

	return (
		<Table
			expandable
			initialLength={10}
			data={mergedData}
			defaultSort={{
				direction: 'desc',
				orderBy: 'views',
				handler: (a, b) => b.views - a.views
			}}
			fields={[
				{
					id: 'product',
					title: 'Product',
					formatter: (item) => (
						<div className={styles.product} key={item.barcode}>
							<img className={styles.productImage} src={item.images?.[0] || ProductThumbnailPlaceholder} alt={item.title} />
							<div className={styles.productContent}>
								{item.title && <div className={styles.productTitle}>{item.title}</div>}
								{item.barcode}
							</div>
						</div>
					)
				},
				{
					title: 'Price',
					id: 'price',
					sortFn: {
						asc: (a, b) => a.price - b.price,
						desc: (a, b) => b.price - a.price
					},
					formatter: (item) => (
						<div className={styles.desktopOnly}>
							{Number(item.price).toLocaleString('en', { style: 'currency', currency: item.currency || 'GBP' })}
						</div>
					)
				},
				{
					title: 'Views',
					id: 'views',
					sortFn: {
						asc: (a, b) => a.views - b.views,
						desc: (a, b) => b.views - a.views
					},
					formatter: (item) => (
						<>
							{Number(item.views).toLocaleString()}
							<span className={styles.mobileOnly}> views</span>
						</>
					)
				},
				{
					title: 'Location searches',
					id: 'location_searches',
					sortFn: {
						asc: (a, b) => a.location_searches - b.location_searches,
						desc: (a, b) => b.location_searches - a.location_searches
					},
					formatter: (item) => (
						<>
							{Number(item.location_searches).toLocaleString()}
							<span className={styles.mobileOnly}> location searches</span>
						</>
					)
				},
				{
					title: 'Directions clicks',
					id: 'directions_clicks',
					sortFn: {
						asc: (a, b) => a.directions_clicks - b.directions_clicks,
						desc: (a, b) => b.directions_clicks - a.directions_clicks
					},
					formatter: (item) => (
						<>
							{Number(item.directions_clicks).toLocaleString()}
							<span className={styles.mobileOnly}> directions clicks</span>
						</>
					)
				},
				{
					title: 'Stock status',
					id: 'stock',
					formatter: (item) => <OrganisationStockStatus barcode={item.barcode} />
				}
			]}
		/>
	);
};

const ProductLocatorPopularProducts = () => {
	const { selectedRange } = useInsightsDate();

	return (
		<Card className={styles.container}>
			<Stats.Header>
				<div>
					<Stats.Title>Most popular products</Stats.Title>
					<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
				</div>
			</Stats.Header>
			<Suspense fallback={<Loader />}>
				<PopularProductsContent />
			</Suspense>
		</Card>
	);
};

export default ProductLocatorPopularProducts;
