import axios from 'axios';
import { getAuthSession } from './auth';
import mixpanel from 'mixpanel-browser';

function createClient(accessToken) {
	return axios.create({
		headers: {
			...(accessToken && { Authorization: `Bearer ${accessToken}` })
		}
	});
}

export default async function myShopClient() {
	const { email, token } = await getAuthSession();
	if (email.includes('@near.st')) {
		// Disable Mixpanel tracking for internal accounts
		mixpanel.disable();
	}
	return createClient(token);
}
