import React from 'react';
import { format } from 'date-fns';
import * as Chart from 'recharts';

import { TooltipContainer } from '@components/Graphs/Tooltip';
import styles from './StackedColourGraph.module.scss';

const transformSeriesData = (series) => {
	return Object.values(
		series.reduce((accumulator, current) => {
			current.dataset.forEach((item) => {
				if (!accumulator[item.label]) {
					accumulator[item.label] = {
						date: format(new Date(item.label), 'MMM d'),
						preliminary: item.preliminary
					};
				}
				accumulator[item.label][current.label] = item.value;
			});
			return accumulator;
		}, {})
	);
};

const GBPTooltip = ({ active, payload, label, contentStyle }) => {
	if (!active || !payload || !payload.length) {
		return null;
	}

	const preliminary = payload.some((item) => item.payload.preliminary);

	return (
		<TooltipContainer contentStyle={contentStyle}>
			{label && (
				<p>
					<strong>{label}</strong>
				</p>
			)}
			{payload.map((item) => (
				<p style={{ color: item.stroke }} key={item.name}>
					{item.name}: {item.value.toLocaleString()}
				</p>
			))}
			{preliminary ? <p className={styles.tooltipNote}>Data may be adjusted retroactively</p> : null}
		</TooltipContainer>
	);
};

const StackedColourGraph = ({ series, className, label = '' }) => {
	const data = transformSeriesData(series);
	const preliminaryIndex = data.findIndex((d) => d.preliminary);

	return (
		<div className={`${styles.graph} ${className}`}>
			<Chart.ResponsiveContainer width="100%" height="100%">
				<Chart.LineChart
					data={data}
					style={{
						fontSize: '0.8rem',
						fontFamily: "'nearst-body', -apple-system, 'Helvetica Neue', 'Arial', sans-serif"
					}}
				>
					<Chart.XAxis
						aria-roledescription="axis"
						aria-orientation="horizontal"
						aria-label="date"
						allowDuplicatedCategory
						dataKey="date"
						interval="equidistantPreserveStart"
						tickFormatter={(date) => format(new Date(date), 'MMM d')}
					/>
					<Chart.YAxis
						tickFormatter={(v) => v.toLocaleString()}
						aria-label="Views"
						aria-roledescription="axis"
						aria-orientation="vertical"
						allowDuplicatedCategory
					>
						<Chart.Label angle={-90} value={label} position="insideLeft" />
					</Chart.YAxis>
					<Chart.Tooltip content={GBPTooltip} />
					{series.map((s, i) => {
						const preliminaryPointIndex = s.dataset.findIndex((point) => point.preliminary);
						const hasPreliminaryData = preliminaryPointIndex !== -1;
						const gradientOffset = `${(preliminaryPointIndex / s.dataset.length) * 100}%`;
						const gradientId = `gradient_${i}`;

						return (
							<React.Fragment key={i}>
								{hasPreliminaryData && (
									<defs>
										<linearGradient id={gradientId} x1="0" y1="0" x2="100%" y2="0">
											<stop offset="0%" stopColor={s.backgroundColor} />
											<stop offset={gradientOffset} stopColor={s.backgroundColor} />
											<stop offset={gradientOffset} stopColor="grey" />
											<stop offset="100%" stopColor="grey" />
										</linearGradient>
									</defs>
								)}

								<Chart.Line
									type="monotone"
									dataKey={s.label}
									dot={false}
									strokeWidth={3}
									stackId={1}
									stroke={hasPreliminaryData ? `url(#${gradientId})` : s.backgroundColor}
									fill={s.backgroundColor}
									fillOpacity={1}
								/>
							</React.Fragment>
						);
					})}
					{preliminaryIndex > -1 && (
						<Chart.ReferenceArea
							x1={data[preliminaryIndex - 1]?.date}
							x2={data[data.length - 1].date}
							y1={0}
							y2={Math.max(...data.map((d) => d.value))}
							fillOpacity={0.1}
						/>
					)}
				</Chart.LineChart>
			</Chart.ResponsiveContainer>
		</div>
	);
};

export default StackedColourGraph;
