import React from 'react';
import * as Stats from '@components/StatsCard';

import { useInsightsDate } from '@services/InsightsDateProvider';
import { format } from 'date-fns';
import InsightsWrapper, { getCSVData } from '../../../../components/Graphs/DataWrapper';
import InsightCardContainer from '../InsightCardContainer';
import Table from '@components/Table';
import styles from './PopularProductsCard.module.scss';
import mixpanel from 'mixpanel-browser';
import { useShop } from '@services/ShopProvider';

const PopularProductsCard = () => {
	const { startDate, endDate, selectedRange } = useInsightsDate();
	const { data: shops, shop } = useShop();

	const query = {
		table: 'product_local_ads',
		from: format(startDate, 'yyyy-MM-dd'),
		to: format(endDate, 'yyyy-MM-dd'),
		id: shop.id ? [shop.id] : undefined,
		organisationId: shop.id ? undefined : shops[0].organisationId,
		columns: ['clicks', 'impressions', 'barcode'],
		min: ['product_name'],
		sort: [{ column: 'impressions', order: 'desc' }],
		sum: ['clicks', 'impressions'],
		limit: 100
	};

	const filename = `popular_products__${format(startDate, 'yyyy-MM-dd')} ${format(endDate, 'yyyy-MM-dd')}.csv`.replace(/ /g, '_');

	return (
		<InsightCardContainer>
			<Stats.Title>
				<span className={styles.title}>
					Popular products
					<Stats.Tooltip>These are the products people clicked on most</Stats.Tooltip>
				</span>
			</Stats.Title>
			<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
			<InsightsWrapper query={query}>
				{(data) => {
					if (!data.length) {
						return <p>We don't have enough data to show you this yet.</p>;
					}
					const csv = getCSVData(data);
					const tableData = data.map((item) => {
						return { product: item.product_name, clicks: item.clicks, impressions: item.impressions };
					});
					return (
						<>
							<Stats.Download csv={csv} filename={filename} />
							<Table
								data={tableData}
								defaultSort={{ orderBy: 'clicks', direction: 'desc', handler: (a, b) => b.clicks - a.clicks }}
								expandable
								initialLength={6}
								maxLength={40}
								onExpand={(expanded) =>
									mixpanel.track(expanded ? 'Expand insights table' : 'Collapse insights table', {
										'Table name': 'Local Ads Popular Products'
									})
								}
								fields={[
									{
										id: 'product',
										title: 'Product name',
										width: '65%',
										formatter: (row) => {
											return row.product;
										}
									},
									{
										id: 'impressions',
										title: 'Impressions',
										width: '20%',
										formatter: (row) => {
											return <div className={styles.impressionsRow}>{row.impressions.toLocaleString()}</div>;
										},
										sortFn: {
											asc: (a, b) => a.impressions - b.impressions,
											desc: (a, b) => b.impressions - a.impressions
										}
									},
									{
										id: 'clicks',
										title: 'Clicks',
										width: '15%',
										formatter: (row) => {
											return <div className={styles.clicksRow}>{row.clicks.toLocaleString()}</div>;
										},
										sortFn: { asc: (a, b) => a.clicks - b.clicks, desc: (a, b) => b.clicks - a.clicks }
									}
								]}
							/>
						</>
					);
				}}
			</InsightsWrapper>
		</InsightCardContainer>
	);
};

export default PopularProductsCard;
