import React from 'react';
import { Card } from '@nearst/ui';
import * as geolib from 'geolib';

import { useShop } from '@services/ShopProvider';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { useStoredQuery } from '@services/insights';

import * as Stats from '@components/StatsCard';
import Skeleton from '@components/Skeleton';

import styles from './TopStores.module.scss';

const mapProximityPerformance = (events, shops) => {
	const unit = 'mi';
	const proximityRange = 20;
	const limit = 10;

	const eventToClosestShop = new Map();

	events.forEach((event) => {
		let closestShopDistance = Infinity;
		let closestShopId = null;

		shops.forEach((shop) => {
			const shopLocation = JSON.parse(shop.geolocation);
			const eventProximity = geolib.convertDistance(geolib.getDistance(shopLocation, event), unit);

			if (eventProximity <= proximityRange && eventProximity < closestShopDistance) {
				closestShopDistance = eventProximity;
				closestShopId = shop.id;
			}
		});

		if (closestShopId) {
			eventToClosestShop.set(event, closestShopId);
		}
	});

	return shops
		.map((shop) => {
			const totalNearbyEvents = Array.from(eventToClosestShop.values()).filter((closestShopId) => closestShopId === shop.id).length;

			return {
				shopId: shop.id,
				name: shop.name,
				city: shop.city,
				totalNearbyEvents
			};
		})
		.sort((a, b) => b.totalNearbyEvents - a.totalNearbyEvents)
		.slice(0, limit);
};

const TopStores = () => {
	const { data: shops } = useShop();
	const { selectedRange } = useInsightsDate();
	const { data: events, isLoading } = useStoredQuery(
		'product-locator/locations',
		{},
		{
			revalidateOnFocus: false,
			refreshWhenOffline: false
		}
	);

	return (
		<Card>
			<Stats.Header>
				<div>
					<Stats.Title>Top stores by search proximity</Stats.Title>
					<Stats.Subtitle>{selectedRange}</Stats.Subtitle>
				</div>
			</Stats.Header>
			<div className={styles.rankings}>
				{isLoading ? (
					<Skeleton height={500} width={'100%'} />
				) : (
					mapProximityPerformance(events, shops).map((location) => (
						<div className={styles.locationResult} key={location.shopId}>
							<div>
								<p>{location.name}</p>
								<p>{location.city}</p>
							</div>
							<div>{location.totalNearbyEvents} customers</div>
						</div>
					))
				)}
			</div>
		</Card>
	);
};

export default TopStores;
