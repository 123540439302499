import React from 'react';
import { UpdateNotification } from 'react-update-popup';

import { Button } from '@nearst/ui';

import styles from './UpdatePopup.module.scss';

const UpdatePopup = () => {
	if (!import.meta.env.PROD) {
		// Only show update popup in production
		return null;
	}

	return (
		<UpdateNotification>
			<div className={styles.container}>
				A new version of the app is available
				<Button primary small onClick={() => window.location.reload()}>
					Reload
				</Button>
			</div>
		</UpdateNotification>
	);
};

export default UpdatePopup;
