import { Button, Stack, Card } from '@nearst/ui';
import React, { useEffect, useState } from 'react';
import { useShop } from '@services/ShopProvider';
import { createChannel } from '@services/channels';
import BusinessSelector from '../../BusinessSelector';
import { facebookApi, findOrCreateCatalog, findPages, loadFacebookSdk, openFBLogin } from '../../../helpers/metaCommerce';
import styles from './MetaCommerceStepOne.module.scss';

const MetaCommerceStepOne = () => {
	const { shop, updateShop } = useShop();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [loadingMessage, setLoadingMessage] = useState();

	const [businesses, setBusinesses] = useState(null);
	const [selectedBusiness, setSelectedBusiness] = useState(null);

	useEffect(loadFacebookSdk, []);

	const selectBusiness = async (id) => {
		setLoading(true);

		try {
			setLoadingMessage('Linking to your business account');
			// Create relationship
			await facebookApi(`/${import.meta.env.VITE_NEARST_BUSINESS_ID}/managed_businesses`, 'POST', {
				existing_client_business_id: id
			});

			// Create system user
			const systemUser = await facebookApi(`/${id}/access_token`, 'POST', {
				scope: 'catalog_management,business_management',
				app_id: import.meta.env.VITE_NEARST_APP_ID
			});

			setLoadingMessage('Creating product catalog');
			// Create catalog
			const catalogId = await findOrCreateCatalog(id, shop);

			// Assign catalog to system user
			const systemUserMe = await facebookApi('/me', 'GET', { access_token: systemUser.access_token });
			await facebookApi(`/${catalogId}/assigned_users`, 'POST', {
				tasks: ['MANAGE'],
				user: systemUserMe.id
			});

			const facebookPages = await findPages(id);

			// Update shop record (temporary)
			await updateShop(shop.id, {
				facebookCatalogEnabled: true,
				facebookAccessToken: systemUser.access_token,
				facebookCatalogId: catalogId,
				facebookPages,
				reprocessAt: 0 // to force full ingest next upload
			});

			// Create channel
			await createChannel(shop.id, 'meta-commerce', {
				config: {
					accessToken: systemUser.access_token,
					catalogId: catalogId,
					businessId: id,
					systemUserId: systemUserMe.id,
					facebookPages
				},
				status: 'pending'
			});
		} catch (e) {
			console.log(e);
			setError(e.message || 'unexpected error');
		}
		setLoading(false);
	};

	return (
		<Card
			progress={25}
			title="Step 1: Connect your Meta Business Account"
			description="Please ensure you have access for the Meta Business Account you would like to connect."
		>
			{loading ? (
				<Card.Loader message={loadingMessage} />
			) : (
				<Stack>
					{!businesses || error ? (
						<div className={styles.buttons}>
							<Button onClick={() => openFBLogin(setError, setLoading, setBusinesses)} primary>
								Log in with existing account
							</Button>
						</div>
					) : null}

					{error && (
						<p className={styles.error}>
							Something went wrong. Please try it again after checking your account permissions.
							<br />
							<code>{error}</code>
						</p>
					)}

					{businesses && !error ? (
						<BusinessSelector
							businesses={businesses}
							setSelectedBusiness={setSelectedBusiness}
							selectBusiness={selectBusiness}
							selectedBusiness={selectedBusiness}
							setBusinesses={setBusinesses}
						/>
					) : null}
				</Stack>
			)}
		</Card>
	);
};

export default MetaCommerceStepOne;
