import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Button, Modal, Icon } from '@nearst/ui';

import { useShop } from '@services/ShopProvider';
import LocationLabel from '../LocationLabel';
import styles from './OrderUpdateModal.module.scss';
import { defaultMessageConfig } from '@utils/emailConfigurations';

export const OrdersUpdateModalContent = ({
	shop,
	pendingUpdate,
	onModalClose,
	onSubmit,
	localCheckoutChannel,
	productReservationChannel
}) => {
	const [skipEmail, setSkipEmail] = useState(false);
	const [emailMessage, setEmailMessage] = useState('');

	const submit = () => {
		onSubmit({ ...pendingUpdate, emailMessage, skipEmail });
		onModalClose();
	};

	useEffect(() => {
		if (!pendingUpdate) return;

		const channelData = pendingUpdate.orderType === 'reservation' ? productReservationChannel : localCheckoutChannel;

		const orderType = pendingUpdate?.status === 'confirmed' ? 'confirmation' : 'cancellation';

		setEmailMessage(channelData?.config?.templates?.[orderType]?.message || defaultMessageConfig[orderType](shop).message);
	}, [shop, pendingUpdate, productReservationChannel, localCheckoutChannel]);

	const emailIsEmpty = !emailMessage.trim().length;

	const label = `Send ${pendingUpdate?.status === 'confirmed' ? 'confirmation' : 'cancellation'} email to customer?`;
	const action = `${pendingUpdate?.status === 'confirmed' ? 'Confirm' : 'Cancel'} order${!skipEmail ? ' & send email' : ''}`;
	const title = `You are ${pendingUpdate?.status === 'confirmed' ? 'confirming' : 'cancelling'} order #${pendingUpdate?.pickupReference}`;

	return (
		<>
			<div className={styles.modalHeader}>
				<div>
					<LocationLabel>{shop?.name}</LocationLabel>
					<h2>{title}</h2>
				</div>
				<Modal.ExitButton onClick={onModalClose} />
			</div>
			<FormGroup>
				<FormControlLabel control={<Checkbox checked={!skipEmail} onClick={() => setSkipEmail(!skipEmail)} />} label={label} />
			</FormGroup>
			{skipEmail ? (
				<p>No emails will be sent to the customer about this order.</p>
			) : (
				<>
					<p className={styles.emailMessageLabel}>
						<strong>Email preview</strong>
						<br />
						Customise the message sent to the customer by updating the text below.
					</p>
					<textarea
						data-testid="orders-update-email-message"
						value={emailMessage}
						name="message"
						onChange={(e) => setEmailMessage(e.target.value)}
						className={styles.emailMessage}
					/>
					{emailIsEmpty && (
						<p className={styles.error}>
							Message cannot be empty.
							<br />
							Please ensure you add relevant information for the customer in the email.
						</p>
					)}
				</>
			)}
			<Button className={styles.button} primary disabled={emailIsEmpty && !skipEmail} onClick={submit} data-testid="confirm-order">
				{action}
			</Button>
		</>
	);
};

const OrdersUpdateModal = ({ pendingUpdate, onModalClose, onSubmit, localCheckoutChannel, productReservationChannel }) => {
	const { data } = useShop();
	const shop = data.find((store) => store.id === pendingUpdate?.pk?.split('#')[1]);

	return (
		<Modal className={styles.modal} isOpen={!!pendingUpdate} onDismiss={onModalClose}>
			<OrdersUpdateModalContent
				shop={shop}
				pendingUpdate={pendingUpdate}
				onModalClose={onModalClose}
				onSubmit={onSubmit}
				localCheckoutChannel={localCheckoutChannel}
				productReservationChannel={productReservationChannel}
			/>
		</Modal>
	);
};

export default OrdersUpdateModal;
