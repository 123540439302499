import { useEffect } from 'react';
import React, { useState } from 'react';
import { Button, Card, CheckboxInput, InputGroup, Select, Input, Textarea } from '@nearst/ui';
import { updateChannel, useChannel } from '@services/channels';
import styles from './EmailConfiguration.module.scss';
import { defaultMessageConfig } from '@utils/emailConfigurations';

/**
 *
 * @param {object} params
 * @param {import('@nearst/shop-data/types').Shop} params.shop
 * @param {string} params.channel {'local-checkout' | 'product-reservations'}
 */
const EmailConfiguration = ({ shop, channel }) => {
	/**
	 * @param {string} template {'placed' | 'confirmation' | 'cancellation'}
	 * @param {function} setTemplate
	 *
	 */
	const [template, setTemplate] = useState('placed');
	const [title, setTitle] = useState(defaultMessageConfig[template](shop).title);
	const [emailMessage, setEmailMessage] = useState(defaultMessageConfig[template](shop).message);
	const [copyTemplate, setCopyTemplate] = useState(true);
	const [loading, setLoading] = useState(false);

	const twinChannelName = channel === 'local-checkout' ? 'product-reservations' : 'local-checkout';
	const { data: channelData, mutate: reloadChannelData } = useChannel(channel, shop.id);
	const { data: twinChannelData } = useChannel(twinChannelName, shop.id);
	const shouldUpdateTwin = twinChannelData?.status === 'enabled';

	useEffect(() => {
		setTitle(channelData?.config?.templates?.[template]?.title || defaultMessageConfig[template](shop).title);
		setEmailMessage(channelData?.config?.templates?.[template]?.message || defaultMessageConfig[template](shop).message);
	}, [channelData, template, shop]);

	const submit = async () => {
		setLoading(true);
		await updateChannel(shop.id, channel, {
			templates: {
				...channelData?.config?.templates,
				[template]: {
					title,
					message: emailMessage
				}
			}
		});

		if (copyTemplate && shouldUpdateTwin) {
			await updateChannel(shop.id, twinChannelName, {
				templates: {
					...twinChannelData?.config?.templates,
					[template]: {
						title,
						message: emailMessage
					}
				}
			});
		}

		reloadChannelData();
		setLoading(false);
	};

	return (
		<Card
			title="Configure email templates"
			description="Edit the default text used in order management emails to match your tone of voice and offer additional information, such as pick-up windows."
		>
			<InputGroup label="Template" htmlFor="template">
				<Select title="Template" value={template} onChange={(e) => setTemplate(e.target.value)} data-testid="template-select">
					<option value={'placed'} data-testid="template-placed">
						Order placed
					</option>
					<option value={'confirmation'} data-testid="template-confirmation">
						Order confirmation
					</option>
					<option value={'cancellation'} data-testid="template-cancellation">
						Order cancellation
					</option>
				</Select>
			</InputGroup>

			<InputGroup htmlFor="header" label="Header">
				<Input
					id="header"
					className={styles.emailTitle}
					value={title}
					onChange={(e) => setTitle(e.target.value)}
					data-testid="template-title"
				/>
			</InputGroup>

			<InputGroup htmlFor="templateText" label="Email template">
				<Textarea
					id="templateText"
					className={styles.emailMessage}
					value={emailMessage}
					onChange={(e) => setEmailMessage(e.target.value)}
					data-testid="template-message"
				/>
			</InputGroup>

			<div className={styles.actions}>
				{shouldUpdateTwin && (
					<CheckboxInput
						label={`Apply to ${twinChannelName.replace('-', ' ')} emails`}
						checked={copyTemplate}
						// @ts-ignore
						onClick={(e) => setCopyTemplate(e.target.checked)}
						disabled={loading}
						data-testid="template-copy-message"
					/>
				)}

				<Button primary className={styles.saveButton} disabled={loading} onClick={submit}>
					Save
				</Button>
			</div>
		</Card>
	);
};

export default EmailConfiguration;
