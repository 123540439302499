import React from 'react';
import { Button, Loader } from '@nearst/ui';

const LoadingButton = ({ loading, disabled, onClick, children, warning = false, className }) => {
	return (
		<Button onClick={onClick} disabled={disabled} primary warning={warning} className={className}>
			{children}
			{loading && <Loader size={20} />}
		</Button>
	);
};

export default LoadingButton;
