export const numberFormat = (value, options = {}) => {
	if (Number.isNaN(value)) return value;
	if (options?.precise) return value.toLocaleString();

	if (value < 1000) {
		return value;
	}

	if (value < 1000000) {
		return `${Math.ceil(value / 1000)}k`;
	}

	return `${Math.ceil(value / 1000000)}m`;
};

export const percentageFormat = (value) => {
	return value.toLocaleString('en', { style: 'percent', minimumFractionDigits: 2 });
};

export const currencyFormat = (currency) => (value) => {
	return value.toLocaleString('en', { style: 'currency', currency });
};
