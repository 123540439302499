import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { Loader, ActualButton, Icon, Modal, Cluster } from '@nearst/ui';
import { disableChannel } from '@services/channels';

import styles from './DisableCheckoutModal.module.scss';

const DisableCheckoutModal = ({ showDialog, setShowDialog, shop }) => {
	const close = () => setShowDialog(false);
	const [loading, setLoading] = useState(false);
	const [updated, setUpdated] = useState(false);

	const confirm = async () => {
		setLoading(true);
		mixpanel.track('Checkout disabled', { distinct_id: shop.id });

		await disableChannel('local-checkout', shop.id);

		setUpdated(true);
		setLoading(false);
	};

	if (updated) {
		return (
			<Modal className={styles.checkoutModal} isOpen={showDialog} onDismiss={close} aria-label="checkout-modal">
				<div className={styles.checkoutModalTitleContainer}>
					<h2 className={styles.checkoutModalTitleText}>Disable checkout</h2>
					<button className={styles.checkoutModalExitButton} onClick={close}>
						<Icon>clear</Icon>
					</button>
				</div>
				<div className={styles.checkoutModalContainer}>
					<div>
						<p>Checkout has been disabled. Your past orders can be accessed through the Local Checkout channels page.</p>
					</div>
				</div>
			</Modal>
		);
	}

	return (
		<Modal className={styles.checkoutModal} isOpen={showDialog} onDismiss={close} aria-label="checkout-modal">
			<div className={styles.checkoutModalTitleContainer}>
				<h2 className={styles.checkoutModalTitleText}>Disable checkout</h2>
				<button className={styles.checkoutModalExitButton} onClick={close}>
					<Icon>clear</Icon>
				</button>
			</div>
			<div className={styles.checkoutModalContainer}>
				<div>
					<p>If you disable local checkout and want to re-enable at a future date, we will need to restart the setup process.</p>
					<p>Are you sure you want to disable it?</p>

					<Cluster className={styles.checkoutModalActionButton}>
						<ActualButton onClick={confirm} warning>
							Disable checkout
						</ActualButton>
						{loading && <Loader />}
					</Cluster>
				</div>
			</div>
		</Modal>
	);
};

export default DisableCheckoutModal;
