import React from 'react';
import mixpanel from 'mixpanel-browser';
import { Card, InfoLink } from '@nearst/ui';
import { FAQ, FAQItem } from '../FAQ';
import styles from './EducationalBlock.module.scss';
import { useShop } from '@services/ShopProvider';

const EducationalBlock = () => {
	const shop = useShop();
	return (
		<div className={styles.info}>
			<Card className={styles.graphic}>
				<video
					autoPlay
					playsInline
					muted
					loop
					poster="https://near.st/cms/iPhoneX_Local-Inventory-Ad_Pharmacy.jpg?mtime=20210819085921&amp;focal=none"
				>
					<source
						title="Local Inventory Ads"
						src="https://near.st/cms/Videos/LIA.mp4?mtime=20210104105724&amp;focal=none"
						type="video/mp4"
					/>
				</video>
			</Card>
			<Card
				className={styles.faqs}
				onClick={() => {
					mixpanel.track('LIA upgrade FAQ (new insights page)', {
						'Shop ID': shop.id,
						'Shop LegacyId': shop.legacyId
					});
				}}
			>
				<FAQ defaultValue="q1">
					<FAQItem value="q1" question="What are NearSt Local Ads?">
						<p>
							Starting from £2/day, NearSt offers Google Local Inventory Ads. Local Inventory Ads appear at the top of Google
							for nearby shoppers searching for something you stock. When a shopper near your store searches for an item in
							Google that you stock, your product will show at the top of the search results with a distance tag, showing them
							how easy it is to buy from you.
						</p>
						<p className={styles.noPad}>For more information, take a look at this article:</p>
						<InfoLink
							className={styles.infolink}
							href="https://support.near.st/en/articles/6682892-what-are-google-local-inventory-ads"
						>
							What are Google Local Inventory Ads?
						</InfoLink>
					</FAQItem>
					<FAQItem value="q2" question="How are Local Ads different to what's included in my plan?">
						<p>
							One of the core features NearSt offers is Google See What&rsquo;s in Store. This allows shoppers searching for
							your business to see your business details and check what you have in stock before visiting your store.
						</p>
						<p>
							Local Inventory Ads (LIAs) are a step up from this - they actively promote your in-store inventory and are
							designed to increase footfall to your shop. When a user near your shop searches for a product you stock, Google
							shows an image, description and title of your product at the top of the search results, along with the distance
							between the searcher and your shop, so they can see the product is available nearby.
						</p>
						<p className={styles.noPad}>Learn more about the difference here:</p>
						<InfoLink
							className={styles.infolink}
							href="https://support.near.st/en/articles/6682840-what-s-the-difference-between-google-see-what-s-in-store-and-google-local-inventory-ads"
						>
							What&rsquo;s the difference between Google See What&rsquo;s in Store and Google Local Inventory Ads?
						</InfoLink>
					</FAQItem>
					<FAQItem value="q3" question="How do I activate Local Inventory Ads?">
						<p>
							To get started, navigate to the Local Ads page where you&rsquo;ll find several
							different daily budgets available, starting from just £2 per day. On this page, you can see an estimate of how
							much impact we think each budget will have for your store. Once you&rsquo;re happy with a daily amount, select
							it, confirm the package, and you&rsquo;re ready to go!
						</p>

						<InfoLink className={styles.infolink} href="/subscription">
							Get started on the Local Ads page
						</InfoLink>
					</FAQItem>
				</FAQ>
				<p className={styles.noPad}>For more FAQs, please take a look at some of our help articles:</p>
				<InfoLink
					className={styles.infolink}
					href="https://support.near.st/en/articles/6682892-what-are-google-local-inventory-ads"
				>
					Help Articles
				</InfoLink>
				<InfoLink className={styles.infolink} href="https://blog.near.st/local-inventory-ads">
					How to make the most out of Google Local Inventory Ads
				</InfoLink>
			</Card>
		</div>
	);
};

export default EducationalBlock;
