import React from 'react';
import { classnames } from '../../../utils';

import styles from './Wrap.module.scss';

const Wrap = ({ children, space = '1rem', className = '', ...restOfProps }) => {
	return (
		<div style={{ '--space': space }} className={classnames(styles.wrap, className)} {...restOfProps}>
			{children}
		</div>
	);
};

export default Wrap;
