import React, { Suspense } from 'react';
import * as Chart from 'recharts';
import { differenceInMinutes, format } from 'date-fns';
import * as geolib from 'geolib';
import { MapPinIcon } from '@heroicons/react/20/solid';

import { Card } from '@nearst/ui';
import { useShop } from '@services/ShopProvider';
import * as Stats from '@components/StatsCard';
import Skeleton from '@components/Skeleton';
import { TooltipContainer } from '@components/Graphs/Tooltip';
import { useStoredQuery } from '@services/insights';
import plurify from '@utils/plurify';
import styles from './RealTimeEventsCard.module.scss';
import { formatTimeAgo, codeToCountry } from '../../../utils';

const calculateNearestShopDistance = (search, shops) => {
	return shops.reduce((acc, shop) => {
		const shopLocation = JSON.parse(shop.geolocation);
		const eventDistance = geolib
			.convertDistance(geolib.getDistance(shopLocation, { latitude: search.latitude, longitude: search.longitude }), 'mi')
			.toFixed(1);

		if (acc === undefined) return { eventDistance, shopName: shop.name };
		if (eventDistance < acc.eventDistance) return { eventDistance, shopName: shop.name };
		return acc;
	}, undefined);
};

const RealtimeEventsCardSkeleton = () => {
	return (
		<>
			<Stats.Header>
				<div>
					<Stats.Title>Real-time events</Stats.Title>
					<Stats.Subtitle>active customers in the last hour</Stats.Subtitle>
				</div>
			</Stats.Header>
			<Skeleton height={96} className={styles.skeleton} />
		</>
	);
};

const RealtimeEventsCard = () => {
	return (
		<Card sectioned>
			<Card.Section>
				<Suspense fallback={<RealtimeEventsCardSkeleton />}>
					<RealtimeEventsGraph />
				</Suspense>
			</Card.Section>
			<Card.Section>
				<Stats.Header>
					<div className={styles.productSearchTitle}>
						<Stats.Title>Latest location searches</Stats.Title>
						<Stats.Tooltip>The location of shoppers searching for their nearest store</Stats.Tooltip>
					</div>
				</Stats.Header>
				<Suspense fallback={<Skeleton height={138} className={styles.skeleton} />}>
					<RealTimeProductSearches />
				</Suspense>
			</Card.Section>
		</Card>
	);
};

const RealtimeEventsTooltip = ({ contentStyle, label, payload }) => {
	const count = payload[0]?.value || 0;

	return (
		<TooltipContainer contentStyle={contentStyle}>
			<strong>
				{count.toLocaleString()} {plurify('visitor', count)}
			</strong>
			<p>{differenceInMinutes(new Date(), new Date(label))} minutes ago</p>
		</TooltipContainer>
	);
};

const RealTimeSearch = ({ searchEvent }) => {
	return (
		<div className={styles.searchEvent}>
			<div className={styles.eventInfo}>
				<div className={styles.areaName}>
					{searchEvent.name}, {searchEvent.country}
				</div>
				<div className={styles.date}>{formatTimeAgo(searchEvent['seen_at'])}</div>
			</div>
			<div className={styles.distance}>
				<MapPinIcon className={styles.pinIcon} />
				{searchEvent.nearestShopDistance} miles to {searchEvent.nearestShop}
			</div>
		</div>
	);
};

const RealTimeProductSearches = () => {
	const { data: recentNearbySearches } = useStoredQuery(
		'product-locator/latest-location-searches',
		{},
		{ suspense: true, refreshInterval: 10_000 }
	);
	const { data: shops } = useShop();

	const searchesWithDistanceToShop = recentNearbySearches.map((search) => {
		const { eventDistance, shopName } = calculateNearestShopDistance(search, shops);

		return {
			nearestShopDistance: eventDistance,
			nearestShop: shopName,
			country: codeToCountry(search['country_code']),
			...search
		};
	});

	return searchesWithDistanceToShop.map((searchEvent) => {
		return <RealTimeSearch key={searchEvent.longitude} searchEvent={searchEvent} />;
	});
};

const RealtimeEventsGraph = () => {
	const { data } = useStoredQuery('product-locator/realtime-events', {}, { suspense: true, refreshInterval: 10_000 });
	const totalVisitors = data.reduce((acc, { visitors }) => acc + visitors, 0);

	// To be able to use type "number" in the XAxis, we need to convert the date to a timestamp
	const withTimestamps = data.map((item) => ({ ...item, timestamp: new Date(item.date).valueOf() }));

	return (
		<>
			<Stats.Header>
				<div>
					<Stats.Title>Real-time events</Stats.Title>
					<Stats.Subtitle>{totalVisitors} active customers in the last hour</Stats.Subtitle>
				</div>
			</Stats.Header>
			<Chart.ResponsiveContainer width="100%" height={112}>
				<Chart.BarChart
					aria-roledescription="Bar chart showing real-time visitors in the last hour"
					margin={{ top: 12, bottom: -10, left: 7, right: 7 }}
					style={{ fontSize: '0.8rem' }}
					data={withTimestamps}
				>
					<Chart.YAxis hide domain={[0, (dataMax) => Math.max(4, dataMax)]} />
					<Chart.XAxis
						dataKey="timestamp"
						stroke="translate"
						domain={['dataMin', 'dataMax']}
						type={'number'}
						interval="preserveStartEnd"
						tickFormatter={(date) => format(new Date(date), 'HH:mm')}
					/>
					<Chart.Tooltip cursor={{ fill: 'var(--grey-lightest' }} content={RealtimeEventsTooltip} />

					<Chart.Bar dataKey="visitors" fill="var(--blue)" aria-label="value" radius={[2, 2, 0, 0]} minPointSize={1}>
						{withTimestamps.map((item) => (
							<Chart.Cell key={item.ts} fill={!item.visitors ? 'var(--grey-lighter)' : 'var(--blue)'} />
						))}
					</Chart.Bar>
				</Chart.BarChart>
			</Chart.ResponsiveContainer>
		</>
	);
};

export default RealtimeEventsCard;
