import React, { useState, Suspense } from 'react';
import { Loader } from '@nearst/ui';
import ErrorMessage from '@components/ErrorMessage';
import { startInventoryConnection } from '@services/onboarding';
import { useShop } from '@services/ShopProvider';
import ReconnectDropdown from './ReconnectDropdown';
import styles from './Reconnect.module.scss';

const Reconnect = ({ inventorySource, kind }) => {
	const [errorMsg, setErrorMsg] = useState('');
	const [resultMessage, setResultMessage] = useState('');
	const { shop } = useShop();

	const handleStartPOSConnect = async (provider) => {
		try {
			setResultMessage('');
			const response = await startInventoryConnection({
				shopId: shop.id,
				provider,
				redirectUrl: `/inventory/${shop.id}`,
				kind: inventorySource?.kind || kind
			});

			if (response && response.finishSetupLink) {
				window.open(response.finishSetupLink, '_blank');
			} else if (response && response.label) {
				setResultMessage(response.label);
			} else {
				setErrorMsg('Unable to start your POS connection. Please try again.');
			}
		} catch (e) {
			console.error('Error - failed to start pos connect', e);
			setErrorMsg('Unable to start your POS connection. Please try again.');
		}
	};

	return (
		<div className={styles.wrap}>
			<Suspense fallback={<Loader size={20} />}>
				<ReconnectDropdown inventorySource={inventorySource} kind={kind} handlePosConnect={handleStartPOSConnect} />
			</Suspense>
			<p className={styles.resultMessage}>{resultMessage}</p>
			<ErrorMessage>{errorMsg}</ErrorMessage>
		</div>
	);
};

export default Reconnect;
