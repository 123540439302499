import React, { useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, Popper, List, ListItem } from '@material-ui/core';
import Icon from '../Icon';
import Button from '../Button';
import { classnames } from '../../utils';
import styles from './DropdownButton.module.scss';

const Context = createContext();

const DropdownItem = ({ onClick, children, className = '', active = false, ...props }) => {
	const handleClose = useContext(Context);
	return (
		<ListItem
			onClick={(e) => {
				handleClose();
				onClick(e);
			}}
			className={`${styles.action} ${active ? styles['action--active'] : ''} ${className}`}
			{...props}
		>
			{children}
		</ListItem>
	);
};

const DropdownMenu = ({
	buttonText,
	primary = true,
	secondary = false,
	disabled = false,
	mini = false,
	icon = 'keyboard_arrow_down',
	children,
	placement = 'bottom-start',
	className = '',
	innerClassName = '',
	...props
}) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const handleClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<div>
				<Button
					mini={mini}
					onClick={handleClick}
					primary={primary}
					secondary={secondary}
					disabled={disabled}
					data-open={open ? 'open' : undefined}
					className={className}
				>
					{buttonText}
					<Icon>{icon}</Icon>
				</Button>
				<Popper
					id={id}
					className={styles['menu-container']}
					placement="bottom-start"
					open={open}
					anchorEl={anchorEl}
					data-open={open ? 'open' : undefined}
					{...props}
				>
					<div className={classnames(styles['menu-items'], innerClassName)}>
						<List disablePadding>
							<Context.Provider value={handleClose}>{children}</Context.Provider>
						</List>
					</div>
				</Popper>
			</div>
		</ClickAwayListener>
	);
};

DropdownMenu.propTypes = {
	buttonText: PropTypes.string,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node
};
DropdownItem.propTypes = {
	onClick: PropTypes.func,
	className: PropTypes.string,
	children: PropTypes.node
};

export { DropdownItem, DropdownMenu };
