import React from 'react';
import PropTypes from 'prop-types';

import styles from './InfoCard.module.scss';

const InfoCard = ({ title, subtitle, children, classes, column, row, width, height, ...props }) => {
	const classList = [styles.infoCard];

	if (column) {
		classList.push(styles.infoCardColumn);
	}
	if (row) {
		classList.push(styles.infoCardRow);
	}
	if (classes) {
		classList.push(classes);
	}

	return (
		<div className={classList.join(' ')} {...props} style={{ width: width, height: height }}>
			<div className={styles.infoCardTitlesContainer}>
				<h3 className={styles.infoCardTitle}>{title}</h3>
				<h4 className={styles.infoCardSubtitle}>{subtitle}</h4>
			</div>
			{children}
		</div>
	);
};

InfoCard.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	classes: PropTypes.string,
	column: PropTypes.bool,
	row: PropTypes.bool,
	width: PropTypes.string,
	height: PropTypes.string
};

export default InfoCard;
