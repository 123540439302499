import React, { useState } from 'react';

import { Input } from '@nearst/ui';
import { formatPrice } from '@utils/currency';

const PriceMarkupInput = ({ price, currency, defaultValue, onChange, ...props }) => {
	const [value, setValue] = useState(defaultValue || '');
	const newPrice = price * (1 + (value || 0) / 100);

	return (
		<>
			<Input
				suffix="%"
				type="number"
				min="0"
				max="100"
				{...props}
				value={value}
				onChange={({ target }) => setValue(target.value)}
				onBlur={() => {
					if (value === '') return onChange(undefined);
					if (value < 0) {
						setValue('0');
						return onChange(0);
					} else {
						onChange(Number(value) || 0);
					}
				}}
			/>
			<p>Price shown: {formatPrice(newPrice, currency)}</p>
		</>
	);
};

export default PriceMarkupInput;
