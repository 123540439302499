import React from 'react';

import styles from './Stack.module.scss';

const Stack = ({ children, space = '1.5rem', className = '', ...restOfProps }) => {
	return (
		<div style={{ '--space': space }} className={`${styles.stack} ${className}`} {...restOfProps}>
			{children}
		</div>
	);
};

export default Stack;
