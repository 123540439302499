import { subDays } from 'date-fns';

// colourScheme is used in so many places here, makes sense to have it outside the component
const colourScheme = ['#102f52', '#2aca79', '#50a1ff', '#f2ae54', '#b9d9ff', '#ca3866', '#f2ae54'];

const GBP_PRELIMINARY_DAYS = 6;

const isPreliminary = (value, insightDate) => {
	const cutoff = subDays(new Date(), GBP_PRELIMINARY_DAYS);
	return value === 0 && new Date(insightDate) > cutoff;
};

export { colourScheme, isPreliminary };
