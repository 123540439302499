import React from 'react';
import { Fade, Slide } from '@material-ui/core';
import { Loader, Stack } from '@nearst/ui';
import LiaPerformanceCard from '../../components/LiaPerformanceCard';
import PlanCard from '../PlanCard';
import RadiusCard from '../InsightCards/RadiusCard';
import HelpCard from '../HelpCard';
import PopularProductsCard from '../../components/PopularProductsCard';
import LocalSearchTermsCard from '../InsightCards/LocalSearchTermsCard';
import ClicksToSales from '../../components/ClicksToSales';
import EducationalBlock from '../EducationalBlock';
import { useInsights } from '@services/insights';
import { useInsightsDate } from '@services/InsightsDateProvider';
import EventBusyIcon from '@mui/icons-material/EventBusy';

import styles from './InsightsView.module.scss';

const InsightsView = ({ shop }) => {
	const { from, to } = useInsightsDate();

	const { isLoading, isEmpty: hasNoResults } = useInsights([
		{
			id: shop.id,
			table: 'local_ads',
			columns: ['shop_id', 'clicks', 'impressions'],
			sum: ['clicks', 'impressions'],
			from,
			to
		}
	]);

	return (
		<>
			{isLoading ? (
				<Loader className={styles.insightsLoader} />
			) : hasNoResults ? (
				<Slide direction="up" in={hasNoResults} mountOnEnter unmountOnExit>
					<div>
						<div className={styles.noResults}>
							<EventBusyIcon fontSize={'large'} />
							<p>Local Ads Insights unavailable for selected date range.</p>
						</div>
						<EducationalBlock />
					</div>
				</Slide>
			) : (
				<Fade in={!hasNoResults} mountOnEnter unmountOnExit>
					<div>
						<Stack>
							<div className={`${styles.container}`}>
								<LiaPerformanceCard shops={[shop]} className={styles.performanceCard}></LiaPerformanceCard>
								{/* TODO: commented out to make all the figures fit horizontally. Either remove or refactor one day once redesigned. */}
								{/* <PlanCard shop={shop}></PlanCard> */}
							</div>
						</Stack>

						<h3 className={styles.breakHeader}>Advanced insights</h3>
						<Stack>
							<div className={`${styles.container} ${styles.leftEmphasis}`}>
								<RadiusCard shop={shop} />
								<HelpCard />
							</div>
							<div className={styles.container}>
								<PopularProductsCard />
							</div>
							<div className={styles.container}>
								<LocalSearchTermsCard />
								<ClicksToSales shop={shop} />
							</div>
						</Stack>
					</div>
				</Fade>
			)}
		</>
	);
};

export default InsightsView;
