import React from 'react';
import { Outlet } from 'react-router-dom';

import { Page } from '@nearst/ui';

// components
import AnalyticsWrapper from '@components/AnalyticsWrapper';
import ErrorBoundary from '@components/ErrorBoundary';
import LoadingContainer from '@components/LoadingContainer';
import MyShopNavigation from '@components/MyShopNavigation';
import PrivateRoute from '@components/PrivateRouteComponent';
import Sidebar from '@components/Sidebar';

// services
import { ShopProvider } from '@services/ShopProvider';
import { UpgradeModalProvider } from '@services/UpgradeProvider';
import { InsightsDateProvider } from '@services/InsightsDateProvider';
import ScrollToTop from '@utils/ScrollToTop';
import UpdatePopup from '@components/UpdatePopup';

export const RootLayout = () => {
	return (
		<PrivateRoute>
			<ShopProvider>
				<AnalyticsWrapper>
					<InsightsDateProvider>
						<UpgradeModalProvider>
							<ScrollToTop />
							<UpdatePopup />
							<Sidebar.Layout>
								<MyShopNavigation />
								<Page.Main fallback={<LoadingContainer />}>
									<ErrorBoundary>
										<Outlet />
									</ErrorBoundary>
								</Page.Main>
							</Sidebar.Layout>
						</UpgradeModalProvider>
					</InsightsDateProvider>
				</AnalyticsWrapper>
			</ShopProvider>
		</PrivateRoute>
	);
};
