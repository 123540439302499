import React from 'react';
import mixpanel from 'mixpanel-browser';

import * as Stats from '@components/StatsCard';
import Table from '@components/Table';
import { getCSVData } from '@components/Graphs/DataWrapper';

import { useInsightsDate } from '@services/InsightsDateProvider';

import { formatImpressions, formatWebsiteVisits } from '../../../../pages/insights/utils';
import { useIsChain } from '@utils/useIsChain';

const PopularProductsTable = ({ data, displayWebsiteVisits, initialLength = 10 }) => {
	const { endDate } = useInsightsDate();
	const isChain = useIsChain();

	const fields = [
		{
			id: 'product',
			title: 'Product name',
			formatter: (row) => {
				return row.title;
			}
		},
		{
			id: 'impressions',
			title: 'Views',
			formatter: formatImpressions,
			sortable: true,
			width: '12%'
		},
		{
			id: 'clicks',
			title: 'Clicks',
			formatter: (row) => {
				return row.clicks;
			},
			sortable: true,
			width: '12%'
		}
	];

	if (displayWebsiteVisits) {
		fields.push({
			id: 'website_redirects',
			title: 'Website visits',
			formatter: (row) => formatWebsiteVisits(row, endDate),
			sortFn: {
				asc: (a, b) => formatWebsiteVisits(a, endDate) - formatWebsiteVisits(b, endDate),
				desc: (a, b) => formatWebsiteVisits(b, endDate) - formatWebsiteVisits(a, endDate)
			},
			width: '12%'
		});
	}

	return (
		<>
			<Stats.Download
				filename="popular-products"
				csv={getCSVData(
					data?.map((row) => ({
						product: row.title,
						barcode: row.barcode,
						clicks: row.clicks,
						views: row.impressions,
						website_visits: displayWebsiteVisits ? row.website_redirects : null
					}))
				)}
			/>
			<Table
				data={data}
				defaultSort={{ orderBy: 'views', direction: 'desc', handler: (a, b) => b.impressions - a.impressions }}
				initialLength={initialLength}
				maxLength={40}
				expandable
				onExpand={(expanded) =>
					mixpanel.track(expanded ? 'Expand insights table' : 'Collapse insights table', {
						'Table name': `Local Listings Popular Products ${isChain ? 'Chain' : ''}`
					})
				}
				fields={fields}
			/>
		</>
	);
};

export default PopularProductsTable;
