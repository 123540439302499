import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../ProgressBar';
import Loader from '../Loader';
import { classnames } from '../../utils';

import styles from './Card.module.scss';

const Card = ({ title = '', description = '', className = '', sectioned = false, children, progress = undefined, ...props }) => {
	return (
		<div className={classnames(styles.card, className)} {...props}>
			{progress !== undefined && <ProgressBar value={progress} className={styles.progress} />}
			{title || description ? (
				<div className={styles.header}>
					{title ? <Title className={styles.title}>{title}</Title> : null}
					{description ? <div className={styles.description}>{description}</div> : null}
				</div>
			) : null}
			{!sectioned ? <Card.Section className={styles.fullHeight}>{children}</Card.Section> : children}
		</div>
	);
};

const Section = ({ children, className = '', ...props }) => {
	return (
		<div className={classnames(styles.section, className)} {...props}>
			{children}
		</div>
	);
};

const CardLoader = ({ message = '' }) => {
	return (
		<Section>
			<div className={styles.cardLoader}>
				<div>
					<div className={styles.loaderContainer}>
						<Loader size={42} />
					</div>
					{message && <p className={styles.loadingMessage}>{message}</p>}
				</div>
			</div>
		</Section>
	);
};

const Title = ({ children, className = '', ...props }) => {
	return (
		<h3 className={classnames(styles.title, className)} {...props}>
			{children}
		</h3>
	);
};

Card.Section = Section;
Card.Title = Title;
Card.Loader = CardLoader;

Card.propTypes = {
	title: PropTypes.node,
	description: PropTypes.node,
	children: PropTypes.node,
	className: PropTypes.string
};

export default Card;
