import React from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';

import Button from '../Button';
import Icon from '../Icon';
import { classnames } from '../../utils';

import '@reach/dialog/styles.css';
import styles from './Modal.module.scss';

/**
 * Modal component.
 *
 * @typedef {object} CustomModalProps
 * @property {React.ReactNode} children
 * @property {string} [className='']
 *
 * @typedef {import('@reach/dialog').DialogProps & CustomModalProps} ModalProps
 *
 * @param {ModalProps} props
 * @returns {JSX.Element}
 */
const Modal = ({ children, className = '', ...props }) => {
	return (
		<DialogOverlay className={styles.overlay} {...props}>
			<DialogContent className={classnames(styles.container, className)}>{children}</DialogContent>
		</DialogOverlay>
	);
};

/**
 * Modal exit button.
 *
 * @param {object} props
 * @param {(e) => void} props.onClick
 * @param {string} [props.className='']
 * @returns
 */
const ExitButton = ({ onClick, className = '' }) => (
	<button className={classnames(styles.exitButton, className)} onClick={onClick}>
		<Icon>clear</Icon>
	</button>
);

Modal.ExitButton = ExitButton;

/**
 * Modal component with a template layout.
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {(e: any) => void} props.onDismiss
 * @param {boolean} props.isOpen
 * @param {string} props.title
 * @param {string} [props.className='']
 * @param {(e: any) => void} [props.confirmationAction]
 * @param {string} [props.cancel] - Text for the cancel button
 * @param {string} [props.confirm] - Text for the confirm button
 * @param {boolean} [props.showDismissButton=true] - Whether to show the dismiss button
 * @returns {JSX.Element}
 */
const TemplateModal = ({
	children,
	className = '',
	onDismiss,
	isOpen,
	title,
	confirmationAction,
	cancel,
	confirm,
	showDismissButton = true,
	...props
}) => {
	const confirmationActionAndClose = (e) => {
		onDismiss?.(e);
		confirmationAction?.(e);
	};

	return (
		<Modal className={classnames(styles.container, className)} onDismiss={onDismiss} isOpen={isOpen} {...props}>
			<div className={styles.title}>
				<h2>{title}</h2>
				<ExitButton onClick={onDismiss} />
			</div>
			<div className={styles.content}>{children}</div>
			<div className={styles.buttons}>
				{/* this wrapper div is actually necessary for styling */}
				<div>
					{cancel ? (
						<Button data-cy={`modal-cancel`} onClick={confirmationActionAndClose} cancel>
							{cancel}
						</Button>
					) : null}
					{confirm ? (
						<Button data-cy={`modal-confirm`} onClick={confirmationActionAndClose} confirm>
							{confirm}
						</Button>
					) : null}
					{showDismissButton ? <Button onClick={onDismiss}>Cancel</Button> : null}
				</div>
			</div>
		</Modal>
	);
};

export { Modal, TemplateModal };
