import React from 'react';
import { classnames } from '../../utils';

import styles from './InfoLink.module.scss';

const InfoLink = ({ href, classes = '', className = '', children, ...props }) => {
	const classList = [styles.infoLink];

	if (classes) {
		classList.push(classes);
	}

	return (
		<a className={classnames(classList.join(' '), className)} href={href} {...props}>
			{children}
		</a>
	);
};

export default InfoLink;
