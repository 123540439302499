import React, { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { useNavigate } from 'react-router-dom';

import { Card, Page } from '@nearst/ui';
import GMBConnect from '../GMBConnect';
import POSConnect from '../POSConnect';
import ApproveInventory from '../ApproveInventory';
import { useShop } from '@services/ShopProvider';
import { isOverMininumCount } from '../utils';
import { MIN_IN_STOCK_COUNT } from '../constants';
import { useCheckPermission } from '@services/auth/hooks';

import styles from './Onboarding.module.scss';
import PaymentDetails from '../PaymentDetails';
import useSWR from 'swr';
import { getCustomer } from '@services/billing';

/**
 * 'shop.pos.pending',
	'shop.pos.first-upload',
	'shop.signup.handover', // used by cs/sales - not for automated flow
	'shop.google.gmb-requested',
	'shop.google.gmb-approved',
	'shop.google.gmb-nearst-managed',
	'shop.google.setup-started',
	'shop.google.setup-completed',
	'shop.google.pos-link-ok',
	'shop.google.swis-live',
	'shop.trial.skipped',
	'shop.trial.checkin-call-completed',
	'shop.trial.started',
	'shop.trial.ended'
 */

const ONBOARDING_STAGE = { POS: 'pos', GMB: 'gmb', GSA: 'gsa', PAYMENT: 'payment' };

const Onboarding = () => {
	const { shop } = useShop();
	const { data: customer } = useSWR([shop.id, 'customer'], getCustomer);
	const hasPaymentMethod = !!customer?.paymentMethod;
	const [stage, setStage] = useState('');
	let navigate = useNavigate();
	const hasInsightsPermission = useCheckPermission('org:insights:read');

	const homepage = hasInsightsPermission ? '/insights' : '/orders';
	const paymentMethodNotNeeded = shop.keyAccountManaged || shop.stockInventorySource?.pointOfSale === 'eposnow';

	const setOnboardingStage = () => {
		switch (shop.onboardingStatus?.type) {
			case 'shop.pos.pending':
				setStage(ONBOARDING_STAGE.POS);
				break;
			case 'shop.pos.first-upload': {
				// todo: .activeRecords vs .inStockValidLines mismatch between myshop, hq, partner api
				const ingestIsValid = isOverMininumCount(shop?.stockInventorySource?.latestIngest?.activeRecords, MIN_IN_STOCK_COUNT);
				if (!ingestIsValid) {
					setStage(ONBOARDING_STAGE.POS);
					break;
				}
				setStage(ONBOARDING_STAGE.GMB);
				break;
			}
			case 'shop.google.gmb-requested':
				setStage(ONBOARDING_STAGE.GMB);
				break;
			case 'shop.google.gmb-approved':
			case 'shop.google.gmb-nearst-managed':
			case 'shop.google.setup-started':
			case 'shop.google.setup-completed':
			case 'shop.google.pos-link-ok':
				if (hasPaymentMethod || paymentMethodNotNeeded) {
					setStage(ONBOARDING_STAGE.GSA);
				} else {
					setStage(ONBOARDING_STAGE.PAYMENT);
				}
				break;
			/* onboarded */
			default:
				navigate(`${homepage}/${shop.id}`, { replace: true });
				break;
		}
	};

	useEffect(() => {
		setOnboardingStage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shop.onboardingStatus, hasPaymentMethod, paymentMethodNotNeeded]);

	useEffect(() => {
		if (stage) {
			mixpanel.register({ 'Onboarding stage': stage });
			mixpanel.track('Onboarding page view');
		}
	}, [stage]);

	return (
		<>
			<Page.Header>
				<h1 className={styles.onboardingTitle}>Welcome to NearSt!</h1>
				<h3>Getting Started</h3>
				<p>Get your shop set up on NearSt in 4 simple steps.</p>
			</Page.Header>
			<Page.Section>
				<Card sectioned className={styles.onboardingCard}>
					<POSConnect isCurrentStage={stage === ONBOARDING_STAGE.POS} shop={shop} />
					<GMBConnect isCurrentStage={stage === ONBOARDING_STAGE.GMB} shop={shop} />
					<PaymentDetails isCurrentStage={stage === ONBOARDING_STAGE.PAYMENT} shop={shop} />
					<ApproveInventory active={stage === ONBOARDING_STAGE.GSA} />
				</Card>
			</Page.Section>
		</>
	);
};

export default Onboarding;
