import React, { Suspense } from 'react';
import * as Sentry from '@sentry/browser';
import { CenteredLoader as LoaderComponent } from '@nearst/ui';
import { useData } from '@services/insights';
import NoData from '../Placeholders/NoData';

/**
 * @callback children
 * @param {Object[]} resultSet
 * @param {string} csv
 *
 * Retrieve an insight.
 * @param {Object} props
 * @param {() => Promise} props.fetcher
 * @param {any} props.args
 * @param {children} props.children
 * @param {boolean} [props.hideError=false]
 * @param {boolean} [props.hideLoader=false]
 * @param {(() => JSX.Element) | undefined} [props.Placeholder=NoData]
 * @param {() => JSX.Element} [props.ErrorPlaceholder=ErrorComponent]
 * @param {() => JSX.Element} [props.LoaderPlaceholder=LoaderComponent]
 */

// children is a function (google "render prop") that takes the data response from the query returns a component that uses that data as a prop
// hideError is optional - if we want a data load to fail 'silently'
const SimpleWrapper = ({
	fetcher,
	args,
	children,
	hideError = false,
	hideLoader = false,
	Placeholder = NoData,
	ErrorPlaceholder = NoData,
	LoaderPlaceholder = LoaderComponent
}) => {
	const { resultSet, error, isLoading, isEmpty } = useData(fetcher, args);

	if (error) {
		Sentry.captureException(error);
		return hideError ? null : <ErrorPlaceholder />;
	}

	if (isLoading) {
		return hideLoader ? null : <LoaderPlaceholder />;
	}

	if (isEmpty && Placeholder) return <Placeholder />;

	return <Suspense fallback={<LoaderPlaceholder />}>{children(resultSet)}</Suspense>;
};

export default SimpleWrapper;
