import React from 'react';
import { format, startOfWeek } from 'date-fns';

import { useStoredQuery } from '@services/insights';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { useShop } from '@services/ShopProvider';

import { Card } from '@nearst/ui';
import * as Stats from '@components/StatsCard';
import InsightCardContainer from '../../components/InsightCardContainer';
import NoData from '@components/Graphs/Placeholders/NoData';
import PopularProductsTable from '@components/Insights/LocalListings/PopularProductsTable';

const PopularProductsChain = () => {
	const { data: shops } = useShop();
	const displayWebsiteVisits = shops.some((shop) => shop.useProductLink);

	const { fromStartOfWeekStr, toEndOfWeekStr } = useInsightsDate();
	const { data: popularProducts, isLoading } = useStoredQuery(
		'local-listings/popular-products',
		{
			from: fromStartOfWeekStr,
			to: toEndOfWeekStr,
			includeRedirects: displayWebsiteVisits,
			shopIds: shops.map((shop) => shop.id)
		},
		{
			revalidateOnFocus: false,
			refreshWhenOffline: false,
			refreshInterval: 0
		}
	);

	const range = `${format(new Date(fromStartOfWeekStr), 'MMM d, yyyy')} – ${format(new Date(toEndOfWeekStr), 'MMM d, yyyy')}`;
	const isTooRecent = new Date(toEndOfWeekStr) > startOfWeek(new Date());
	const placeholder = <NoData text={isTooRecent ? 'No data available yet for this week' : 'No results'} />;

	return (
		<InsightCardContainer>
			<Stats.Title>Popular Products</Stats.Title>
			<Stats.Subtitle>
				<span>Weekly data: {range} | All locations</span>
				<Stats.Tooltip>Data is segmented by week, updated every Monday.</Stats.Tooltip>
			</Stats.Subtitle>
			<>
				{isLoading ? (
					<Card.Loader />
				) : !popularProducts?.length ? (
					placeholder
				) : (
					<PopularProductsTable data={popularProducts} displayWebsiteVisits={displayWebsiteVisits} />
				)}
			</>
		</InsightCardContainer>
	);
};

export default PopularProductsChain;
