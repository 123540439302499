import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRightIcon, LockClosedIcon } from '@heroicons/react/20/solid';

import { Pill, UItils } from '@nearst/ui';

import styles from './ReportOverviewCard.module.scss';

const ReportOverviewCardDecoration = ({ locked, comingSoon }) => {
	if (comingSoon) {
		return (
			<Pill colour="grey" className={styles.pill}>
				Coming soon
			</Pill>
		);
	}
	if (locked) {
		return <LockClosedIcon />;
	}

	return <ChevronRightIcon />;
};

const ReportOverviewCard = ({ id, title, image, description, locked = false, comingSoon = false }) => {
	return (
		<Link className={UItils.classnames(styles.card, (locked || comingSoon) && styles.locked)} to={locked ? '' : id}>
			<div className={styles.image} style={{ backgroundImage: `url("${image}")` }} />
			<div className={styles.content}>
				<h4>
					{title}
					<ReportOverviewCardDecoration locked={locked} comingSoon={comingSoon} />
				</h4>
				<p>{description}</p>
			</div>
		</Link>
	);
};

export default ReportOverviewCard;
