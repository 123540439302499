import React from 'react';

import Loader from '../Loader/index';
import styles from './CenteredLoader.module.scss';

const CenteredLoader = () => (
	<div className={styles.container}>
		<Loader size={42} />
	</div>
);

export default CenteredLoader;
