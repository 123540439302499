import React from 'react';
import { NavLink, useLocation, useMatches } from 'react-router-dom';

import { useIdSplice } from '@utils/constructUrlWithId';

import { UItils, Logo, Stack, Sidebar as UISidebar } from '@nearst/ui';
import styles from './Sidebar.module.scss';
import Alert from '@components/Alert';

/**
 * @typedef {import('react-router-dom').NavLinkProps} NavLinkProps
 */

/**
 * A menu item (navigation)
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The content to be rendered inside the menu item.
 * @param {string} [props.className] - Additional class names to apply to the menu item.
 * @param {string[]} [props.activePaths] - An array of paths that should trigger the active state.
 * @param {string} props.to - The target path for the navigation link.
 * @param {boolean} [props.end] - Whether this should be an exact match.
 * @param {object} [props.additionalProperties] - Additional properties to be passed to the NavLink component.
 * @returns {React.JSX.Element}
 */
const Item = ({ children, className = '', to, activePaths, end = false, ...additionalProperties }) => {
	const path = useIdSplice(to);
	const location = useLocation();
	const matches = useMatches();

	const isParentRoute =
		activePaths && activePaths.length > 0
			? activePaths.some((activePath) => matches.some((match) => activePath === match.pathname))
			: false;

	const isSamePath = matches.some((match) => path === match.pathname);
	const isActive = location.pathname === to || (!end && location.pathname.startsWith(to));

	// handle external links
	if (typeof to === 'string' && to.startsWith('http')) {
		return (
			<UISidebar.Item className={className}>
				<a target="_blank" rel="noopener noreferrer" href={to} {...additionalProperties}>
					{children}
				</a>
			</UISidebar.Item>
		);
	}

	return (
		<UISidebar.Item className={className} active={isActive || isParentRoute}>
			<NavLink replace={isSamePath} to={path} end={end} {...additionalProperties}>
				{children}
			</NavLink>
		</UISidebar.Item>
	);
};

/**
 * Group a top-level item and a submenu together
 *
 * @param {React.LiHTMLAttributes<HTMLElement>} props
 * @returns {React.JSX.Element}
 */
const Menu = ({ children, className = '', ...rest }) => {
	return (
		<li className={UItils.classnames(className, styles.menu)} {...rest}>
			<ul>{children}</ul>
		</li>
	);
};

/**
 * A submenu item (navigation)
 *
 * @param {NavLinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>} props
 * @returns {React.JSX.Element}
 */
const SubItem = ({ children, className = '', to, ...props }) => {
	return (
		<Item className={UItils.classnames(className, styles.subItem)} to={to} {...props}>
			{children}
		</Item>
	);
};

/**
 * A submenu - to display nested routes within the sidebar
 *
 * @typedef {object} SubmenuProps
 * @property {string} SubmenuProps.prefix The submenu prefix to mark as expanded - e.g. /insights
 *
 * @param {React.LiHTMLAttributes<HTMLElement> & SubmenuProps} props
 * @returns {React.JSX.Element}
 */
const SubMenu = ({ children, prefix }) => {
	const { pathname } = useLocation();
	const active = pathname.startsWith(prefix);

	if (!active) return null;

	return (
		<li className={styles.submenu}>
			<ul>{children}</ul>
		</li>
	);
};

/**
 * Section for Alerts to live in
 *
 * @param {React.LiHTMLAttributes<HTMLElement>} props
 * @returns {React.JSX.Element}
 */
const Alerts = ({ children, className = '', ...rest }) => {
	return (
		<Stack className={UItils.classnames(className, styles.alerts)} {...rest}>
			{children}
		</Stack>
	);
};

/**
 * Main navigation sidebar in the Retailers Dashboard.
 *
 * @param {object} props
 * @param {Boolean} props.mobileExpanded
 * @param {React.ReactNode} props.children
 * @returns {React.JSX.Element}
 */
const Sidebar = ({ mobileExpanded, children }) => {
	return (
		<UISidebar mobileExpanded={mobileExpanded}>
			<ul>{children}</ul>
			<UISidebar.Footer>
				<Alerts>
					<Alert.PaymentWarning />
					<Alert.ClerkImpersonator />
				</Alerts>
				<Logo height="24px" className={styles.logo} />
			</UISidebar.Footer>
		</UISidebar>
	);
};

Sidebar.Layout = UISidebar.Layout;
Sidebar.Group = UISidebar.Group;
Sidebar.Item = Item;
Sidebar.SubItem = SubItem;
Sidebar.Menu = Menu;
Sidebar.SubMenu = SubMenu;
Sidebar.Alerts = Alerts;

export default Sidebar;
