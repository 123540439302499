import React, { useState } from 'react';

import { Button, Card, Icon, InputGroup, Input, Loader } from '@nearst/ui';



import { updateBillingAddress } from '@services/billing';
import { useCheckPermission } from '@services/auth/hooks';
import AuthorizationTooltip from '@components/AuthorizationTooltip';

import styles from './CustomerDetails.module.scss';

const CustomerAddress = ({ shopId, customer, mutate }) => {
	const authorized = useCheckPermission('org:billing:manage');
	const [loading, setLoading] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [values, setValues] = useState({
		line1: '',
		line2: '',
		city: '',
		postal_code: '',
		email: ''
	});

	const clickEdit = () => {
		if (!authorized) return;

		setEditMode(true);
		setValues({ ...customer.address, email: customer.email });
	};

	const submit = async () => {
		setLoading(true);
		try {
			await updateBillingAddress(shopId, values);
			await mutate();
			setEditMode(false);
		} catch (e) {
			alert(e.response?.data || e.message);
		}
		setLoading(false);
	};

	if (editMode) {
		return (
			<Card.Section className="card-details">
				<InputGroup label="Billing address" htmlFor="billing-line-1">
					<div className={styles.billingAddress}>
						<Input
							id="billing-line-1"
							placeholder="Address line 1"
							value={values.line1}
							onChange={(e) => setValues((values) => ({ ...values, line1: e.target.value }))}
						/>
						<Input
							id="billing-line-2"
							placeholder="Address line 2"
							value={values.line2}
							onChange={(e) => setValues((values) => ({ ...values, line2: e.target.value }))}
						/>
						<Input
							id="billing-city"
							placeholder="City"
							value={values.city}
							onChange={(e) => setValues((values) => ({ ...values, city: e.target.value }))}
						/>
						<Input
							id="billing-postal-code"
							placeholder="Postal code"
							value={values.postal_code}
							onChange={(e) => setValues((values) => ({ ...values, postal_code: e.target.value }))}
						/>
					</div>
				</InputGroup>
				<InputGroup label="Billing email" htmlFor="billing-email">
					<Input
						id="billing-email"
						value={values.email}
						onChange={({ target }) => setValues((values) => ({ ...values, email: target.value }))}
					/>
				</InputGroup>

				<Button primary disabled={loading} onClick={submit}>
					Save changes {loading && <Loader size={20} />}
				</Button>
			</Card.Section>
		);
	}

	return (
		<Card.Section className="card-details">
			<div className="switcher--between">
				<div>
					<p>
						{customer.address ? (
							<>
								{customer.address.line1}
								<br />
								{customer.address.line2 ? (
									<>
										{customer.address.line2}
										<br />
									</>
								) : null}
								{customer.address.city}, {customer.address.postal_code}
							</>
						) : (
							'No billing address set'
						)}
					</p>
					<div className="line-with-icon">
						<Icon>email</Icon>
						<span>{customer.email}</span>
					</div>
				</div>
				<AuthorizationTooltip authorized={authorized}>
					<Button disabled={!authorized} onClick={clickEdit}>
						Update billing address
					</Button>
				</AuthorizationTooltip>
			</div>
		</Card.Section>
	);
};

export default CustomerAddress;
