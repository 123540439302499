import React from 'react';

import styles from './Cluster.module.scss';

const Cluster = ({ children, space = '1.5rem', className = '', ...restOfProps }) => {
	return (
		<div style={{ '--space': space }} className={`${styles.cluster} ${className}`} {...restOfProps}>
			<div>{children}</div>
		</div>
	);
};

export default Cluster;
