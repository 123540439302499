import React, { useMemo } from 'react';

import * as Stats from '@components/StatsCard';
import Skeleton from '@components/Skeleton';

import { useShop } from '@services/ShopProvider';
import { useInsightsDate } from '@services/InsightsDateProvider';
import { usePlansForShopIds } from '@services/localAds';
import { getTotalAdsSpend } from '@utils/localAdsPlans';

const LocalAdsSpend = ({ shopIds }) => {
	const { data: shops } = useShop();
	const { startDate, endDate } = useInsightsDate();
	const plans = usePlansForShopIds(shopIds);
	const budget = useMemo(() => getTotalAdsSpend(plans, startDate, endDate, shops), [plans, startDate, endDate, shops]);

	return (
		<Stats.Figure>
			{budget ? (
				budget.spend.toLocaleString('en', {
					style: 'currency',
					currency: shops.find((s) => s.billingCurrency)?.billingCurrency || 'GBP'
				})
			) : (
				<Skeleton width={100} height={32} />
			)}
			<Stats.FigureDescription>
				Ad spend
				<Stats.Tooltip>
					The budget spent for the selected period across {budget.shopsWithLocalAds.size} locations with Local Ads
				</Stats.Tooltip>
			</Stats.FigureDescription>
		</Stats.Figure>
	);
};

export default LocalAdsSpend;
