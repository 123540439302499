import { startOfDay, startOfMonth, startOfWeek, differenceInMinutes, formatRelative, isBefore } from 'date-fns';
import plurify from '@utils/plurify';

export const calculatePercentageChange = (current, previous) => {
	const percentageChange = Math.round(((parseInt(current) - parseInt(previous)) / (parseInt(current) + parseInt(previous) / 2)) * 100);

	if (percentageChange < 0) {
		const regex = /([-])/g;

		return {
			change: percentageChange.toString().replace(regex, ''),
			direction: 'down'
		};
	}
	if (percentageChange === 0 || Number.isNaN(percentageChange)) {
		return {
			change: '0',
			direction: 'none'
		};
	}
	return {
		change: percentageChange.toString(),
		direction: 'up'
	};
};

export const parseNumber = (number) => {
	if (isNaN(+number)) return 0;
	return +number;
};

export const sumField = (fieldName, response) => {
	if (!response) return 0;
	return response.reduce((runningTotal, current) => runningTotal + parseNumber(current[fieldName]), 0);
};

const getDateForInterval = (date, interval) => {
	date = new Date(date);
	if (interval === 'day') {
		date = startOfDay(date);
	}
	if (interval === 'week') {
		date = startOfWeek(date, { weekStartsOn: 1 });
	}
	if (interval === 'month') {
		date = startOfMonth(date);
	}
	return date;
};

export const sumByDate = (arr, interval = 'day') => {
	return arr.reduce((acc, curr) => {
		const date = getDateForInterval(curr.date, interval).toISOString();
		const index = acc.findIndex((item) => item.date === date);
		if (index === -1) {
			acc.push({ ...curr, date });
		} else {
			Object.keys(curr).forEach((key) => {
				if (key !== 'date') {
					acc[index][key] += curr[key];
				}
			});
		}
		return acc;
	}, []);
};

// In cases where local listings have fewer than 20 views, we display '<20' instead of '0'.
// This change addresses a potential confusion where a product might show "0" views but still have clicks.
export const formatImpressions = (listing) => {
	let formattedImpressions = listing.impressions.toLocaleString();
	if (listing.clicks > 0 && listing.impressions === 0) {
		formattedImpressions = '<20';
	}
	return formattedImpressions;
};

// In cases where the insights data query is run before the website redirects were stored, we display '-'.
export const formatWebsiteVisits = (row, endDate) => {
	return isBefore(endDate, new Date('2024-11-01')) ? '-' : Number(row.website_redirects) || 0;
};

export const formatTimeAgo = (date) => {
	const minutesAgo = differenceInMinutes(new Date(), new Date(date));

	if (minutesAgo <= 1) return 'just now';
	if (minutesAgo < 90) return `${minutesAgo} ${plurify('minute', minutesAgo)} ago`;

	return formatRelative(new Date(date), new Date());
};

export const codeToCountry = (countryCode) => {
	const lookup = {
		BD: 'Bangladesh',
		BE: 'Belgium',
		BF: 'Burkina Faso',
		BG: 'Bulgaria',
		BA: 'Bosnia and Herzegovina',
		BB: 'Barbados',
		WF: 'Wallis and Futuna',
		BL: 'Saint Barthelemy',
		BM: 'Bermuda',
		BN: 'Brunei',
		BO: 'Bolivia',
		BH: 'Bahrain',
		BI: 'Burundi',
		BJ: 'Benin',
		BT: 'Bhutan',
		JM: 'Jamaica',
		BV: 'Bouvet Island',
		BW: 'Botswana',
		WS: 'Samoa',
		BQ: 'Bonaire, Saint Eustatius and Saba',
		BR: 'Brazil',
		BS: 'Bahamas',
		JE: 'Jersey',
		BY: 'Belarus',
		BZ: 'Belize',
		RU: 'Russia',
		RW: 'Rwanda',
		RS: 'Serbia',
		TL: 'East Timor',
		RE: 'Reunion',
		TM: 'Turkmenistan',
		TJ: 'Tajikistan',
		RO: 'Romania',
		TK: 'Tokelau',
		GW: 'Guinea-Bissau',
		GU: 'Guam',
		GT: 'Guatemala',
		GS: 'South Georgia and the South Sandwich Islands',
		GR: 'Greece',
		GQ: 'Equatorial Guinea',
		GP: 'Guadeloupe',
		JP: 'Japan',
		GY: 'Guyana',
		GG: 'Guernsey',
		GF: 'French Guiana',
		GE: 'Georgia',
		GD: 'Grenada',
		GB: 'United Kingdom',
		GA: 'Gabon',
		SV: 'El Salvador',
		GN: 'Guinea',
		GM: 'Gambia',
		GL: 'Greenland',
		GI: 'Gibraltar',
		GH: 'Ghana',
		OM: 'Oman',
		TN: 'Tunisia',
		JO: 'Jordan',
		HR: 'Croatia',
		HT: 'Haiti',
		HU: 'Hungary',
		HK: 'Hong Kong',
		HN: 'Honduras',
		HM: 'Heard Island and McDonald Islands',
		VE: 'Venezuela',
		PR: 'Puerto Rico',
		PS: 'Palestinian Territory',
		PW: 'Palau',
		PT: 'Portugal',
		SJ: 'Svalbard and Jan Mayen',
		PY: 'Paraguay',
		IQ: 'Iraq',
		PA: 'Panama',
		PF: 'French Polynesia',
		PG: 'Papua New Guinea',
		PE: 'Peru',
		PK: 'Pakistan',
		PH: 'Philippines',
		PN: 'Pitcairn',
		PL: 'Poland',
		PM: 'Saint Pierre and Miquelon',
		ZM: 'Zambia',
		EH: 'Western Sahara',
		EE: 'Estonia',
		EG: 'Egypt',
		ZA: 'South Africa',
		EC: 'Ecuador',
		IT: 'Italy',
		VN: 'Vietnam',
		SB: 'Solomon Islands',
		ET: 'Ethiopia',
		SO: 'Somalia',
		ZW: 'Zimbabwe',
		SA: 'Saudi Arabia',
		ES: 'Spain',
		ER: 'Eritrea',
		ME: 'Montenegro',
		MD: 'Moldova',
		MG: 'Madagascar',
		MF: 'Saint Martin',
		MA: 'Morocco',
		MC: 'Monaco',
		UZ: 'Uzbekistan',
		MM: 'Myanmar',
		ML: 'Mali',
		MO: 'Macao',
		MN: 'Mongolia',
		MH: 'Marshall Islands',
		MK: 'Macedonia',
		MU: 'Mauritius',
		MT: 'Malta',
		MW: 'Malawi',
		MV: 'Maldives',
		MQ: 'Martinique',
		MP: 'Northern Mariana Islands',
		MS: 'Montserrat',
		MR: 'Mauritania',
		IM: 'Isle of Man',
		UG: 'Uganda',
		TZ: 'Tanzania',
		MY: 'Malaysia',
		MX: 'Mexico',
		IL: 'Israel',
		FR: 'France',
		IO: 'British Indian Ocean Territory',
		SH: 'Saint Helena',
		FI: 'Finland',
		FJ: 'Fiji',
		FK: 'Falkland Islands',
		FM: 'Micronesia',
		FO: 'Faroe Islands',
		NI: 'Nicaragua',
		NL: 'Netherlands',
		NO: 'Norway',
		NA: 'Namibia',
		VU: 'Vanuatu',
		NC: 'New Caledonia',
		NE: 'Niger',
		NF: 'Norfolk Island',
		NG: 'Nigeria',
		NZ: 'New Zealand',
		NP: 'Nepal',
		NR: 'Nauru',
		NU: 'Niue',
		CK: 'Cook Islands',
		XK: 'Kosovo',
		CI: 'Ivory Coast',
		CH: 'Switzerland',
		CO: 'Colombia',
		CN: 'China',
		CM: 'Cameroon',
		CL: 'Chile',
		CC: 'Cocos Islands',
		CA: 'Canada',
		CG: 'Republic of the Congo',
		CF: 'Central African Republic',
		CD: 'Democratic Republic of the Congo',
		CZ: 'Czech Republic',
		CY: 'Cyprus',
		CX: 'Christmas Island',
		CR: 'Costa Rica',
		CW: 'Curacao',
		CV: 'Cape Verde',
		CU: 'Cuba',
		SZ: 'Swaziland',
		SY: 'Syria',
		SX: 'Sint Maarten',
		KG: 'Kyrgyzstan',
		KE: 'Kenya',
		SS: 'South Sudan',
		SR: 'Suriname',
		KI: 'Kiribati',
		KH: 'Cambodia',
		KN: 'Saint Kitts and Nevis',
		KM: 'Comoros',
		ST: 'Sao Tome and Principe',
		SK: 'Slovakia',
		KR: 'South Korea',
		SI: 'Slovenia',
		KP: 'North Korea',
		KW: 'Kuwait',
		SN: 'Senegal',
		SM: 'San Marino',
		SL: 'Sierra Leone',
		SC: 'Seychelles',
		KZ: 'Kazakhstan',
		KY: 'Cayman Islands',
		SG: 'Singapore',
		SE: 'Sweden',
		SD: 'Sudan',
		DO: 'Dominican Republic',
		DM: 'Dominica',
		DJ: 'Djibouti',
		DK: 'Denmark',
		VG: 'British Virgin Islands',
		DE: 'Germany',
		YE: 'Yemen',
		DZ: 'Algeria',
		US: 'United States',
		UY: 'Uruguay',
		YT: 'Mayotte',
		UM: 'United States Minor Outlying Islands',
		LB: 'Lebanon',
		LC: 'Saint Lucia',
		LA: 'Laos',
		TV: 'Tuvalu',
		TW: 'Taiwan',
		TT: 'Trinidad and Tobago',
		TR: 'Turkey',
		LK: 'Sri Lanka',
		LI: 'Liechtenstein',
		LV: 'Latvia',
		TO: 'Tonga',
		LT: 'Lithuania',
		LU: 'Luxembourg',
		LR: 'Liberia',
		LS: 'Lesotho',
		TH: 'Thailand',
		TF: 'French Southern Territories',
		TG: 'Togo',
		TD: 'Chad',
		TC: 'Turks and Caicos Islands',
		LY: 'Libya',
		VA: 'Vatican',
		VC: 'Saint Vincent and the Grenadines',
		AE: 'United Arab Emirates',
		AD: 'Andorra',
		AG: 'Antigua and Barbuda',
		AF: 'Afghanistan',
		AI: 'Anguilla',
		VI: 'U.S. Virgin Islands',
		IS: 'Iceland',
		IR: 'Iran',
		AM: 'Armenia',
		AL: 'Albania',
		AO: 'Angola',
		AQ: 'Antarctica',
		AS: 'American Samoa',
		AR: 'Argentina',
		AU: 'Australia',
		AT: 'Austria',
		AW: 'Aruba',
		IN: 'India',
		AX: 'Aland Islands',
		AZ: 'Azerbaijan',
		IE: 'Ireland',
		ID: 'Indonesia',
		UA: 'Ukraine',
		QA: 'Qatar',
		MZ: 'Mozambique'
	};

	const country = lookup[countryCode];
	if (country) {
		return country;
	} else {
		throw Error(`Country code invalid ${countryCode}`);
	}
};
