import React from 'react';

import OnboardingStep from '../OnboardingStep';
import { ICON_STATUS } from '../constants';
import useSWR from 'swr';
import { getCustomer } from '@services/billing';
import UpdatePaymentLink from '@components/UpdatePaymentLink';

import { CustomerPaymentDetails } from '../../settings/components/CustomerDetails';

const ICON_FALLBACK = '3';
const DEFAULT_TITLE = 'Add payment details for your account.';

const PaymentDetails = ({ isCurrentStage = false, shop }) => {
	const { data: customer } = useSWR([shop.id, 'customer'], getCustomer);
	const hasPaymentMethod = customer?.paymentMethod;
	const paymentStepNotNeeded = shop.keyAccountManaged || shop.stockInventorySource?.pointOfSale === 'eposnow';

	if (hasPaymentMethod) {
		return (
			<OnboardingStep
				iconText={ICON_FALLBACK}
				status={ICON_STATUS.COMPLETED}
				title="Payment details successfully added to your account."
			>
				<p>We already have a payment method listed for you:</p>
				<CustomerPaymentDetails customer={customer} />
			</OnboardingStep>
		);
	}

	if (paymentStepNotNeeded) {
		return (
			<OnboardingStep iconText={ICON_FALLBACK} status={ICON_STATUS.COMPLETED} title="Adding payment details.">
				<p>We've skipped this step.</p>
			</OnboardingStep>
		);
	}
	if (!isCurrentStage) {
		return (
			<OnboardingStep iconText={ICON_FALLBACK} status={ICON_STATUS.DISABLED} title={DEFAULT_TITLE}>
				<></>
			</OnboardingStep>
		);
	}

	return (
		<OnboardingStep iconText={ICON_FALLBACK} status={ICON_STATUS.CURRENT} title={DEFAULT_TITLE}>
			<p>You will be charged only after your inventory is live on your first channel.</p>
			<UpdatePaymentLink shopId={shop.id} primary>
				Add payment method
			</UpdatePaymentLink>
		</OnboardingStep>
	);
};

export default PaymentDetails;
