import React from 'react';
import { endOfISOWeek, endOfMonth, format, startOfISOWeek, startOfMonth } from 'date-fns';

import { UItils } from '@nearst/ui';
import { TooltipContainer } from '../Tooltip';
import { useInsightsDate } from '@services/InsightsDateProvider';
import styles from './TimeSeriesGraph.module.scss';
import { numberFormat } from '../formatters';

export const formatDate = (date, interval) => {
	const dateFormat = 'MMM d, yyyy';

	if (interval === 'month') {
		const start = startOfMonth(date);
		const end = endOfMonth(date);
		return `${format(start, dateFormat)} - ${format(end, dateFormat)}`;
	}

	if (interval === 'week') {
		const start = startOfISOWeek(date);
		const end = endOfISOWeek(date);
		return `${format(start, dateFormat)} - ${format(end, dateFormat)}`;
	}

	return format(new Date(date), dateFormat);
};

const MultiPeriodTooltip = ({ active, payload, lineColor, label, formatter = numberFormat, contentStyle }) => {
	const { interval } = useInsightsDate();

	if (!active || !payload || !payload.length) {
		return null;
	}

	const payloadItem = payload[0].payload;

	// calculate percentage change
	const hasPrevious = payloadItem.previousDate;
	const difference = hasPrevious ? payloadItem.value - payloadItem.previousValue : null;
	const percentageChange = difference && difference / payloadItem.previousValue;

	return (
		<TooltipContainer contentStyle={contentStyle}>
			<p>
				<strong>{label}</strong>
			</p>
			{payloadItem.budget ? (
				<p className={styles.budget}>
					{payloadItem.budget.toLocaleString('en', { style: 'currency', currency: payloadItem.currency || 'GBP' })} daily budget
				</p>
			) : null}
			<p style={{ color: lineColor }}>
				{formatDate(new Date(payloadItem.date), interval)}: {formatter(payloadItem.value, { precise: true })}
				{hasPrevious && percentageChange !== Infinity ? (
					<span className={UItils.classnames(styles.difference, percentageChange > 0 && styles.positive)}>
						{difference > 0 ? '+' : ''}
						{percentageChange.toLocaleString('en', { style: 'percent', maximumFractionDigits: 1 })}
					</span>
				) : null}
			</p>
			{hasPrevious ? (
				<p style={{ color: 'var(--grey-dark)' }}>
					{formatDate(new Date(payloadItem.previousDate), interval)}: {formatter(payloadItem.previousValue, { precise: true })}
				</p>
			) : null}
		</TooltipContainer>
	);
};

export default MultiPeriodTooltip;
