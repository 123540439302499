import React from 'react';
import PropTypes from 'prop-types';

const Tab = ({ passProps = {}, component, children = '', Route, ...props }) => {
	const renderContent = () => {
		if (children) {
			return children;
		}

		const Component = component;
		return <Component {...passProps} />;
	};

	return <Route exact {...props} render={renderContent}></Route>;
};

Tab.propTypes = {
	path: PropTypes.string.isRequired,
	title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.func]).isRequired,
	passProps: PropTypes.object,
	component: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
	children: PropTypes.any
};

export default Tab;
