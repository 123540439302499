import React from 'react';

import { Switcher } from '@nearst/ui';
import * as Stats from '../../../../../components/StatsCard';
import LocalAdsSpend from '@components/Insights/LocalAdsSpend';

import styles from './Figures.module.scss';

const Figures = ({ liaCurrentPeriod, liaPreviousPeriod, showSpend, shopIds }) => {
	const { clicks, impressions } = liaCurrentPeriod.reduce(
		(acc, cur) => {
			acc.clicks += cur.clicks;
			acc.impressions += cur.impressions;
			return acc;
		},
		{ clicks: 0, impressions: 0 }
	);
	const { previousClicks, previousImpressions } = liaPreviousPeriod.reduce(
		(acc, cur) => {
			acc.previousClicks += cur.clicks;
			acc.previousImpressions += cur.impressions;
			return acc;
		},
		{
			previousClicks: 0,
			previousImpressions: 0
		}
	);

	const ctr = !clicks || !impressions ? 0 : ((clicks / impressions) * 100).toFixed(2);
	const previousCtr = !previousClicks || !previousImpressions ? 0 : ((previousClicks / previousImpressions) * 100).toFixed(2);

	return (
		<Switcher space="2rem" className={styles.stats}>
			<Stats.Block description="Impressions" currentValue={Number(impressions)} previousValue={Number(previousImpressions)} />
			<Stats.Block description="Clicks" currentValue={Number(clicks)} previousValue={Number(previousClicks)} />

			<Stats.Figure>
				{ctr}%
				<Stats.FigureDescription>
					CTR
					<Stats.Tooltip>
						Your click-through rate is a measure of how relevant your products are to the search terms
					</Stats.Tooltip>
					<Stats.PercentageChange previousValue={previousCtr} currentValue={ctr} />
				</Stats.FigureDescription>
			</Stats.Figure>

			{showSpend && <LocalAdsSpend shopIds={shopIds} />}
		</Switcher>
	);
};

export default Figures;
