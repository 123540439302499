import React from 'react';
import { Loader } from '@nearst/ui';
import styles from './LoadingContainer.module.css';

export default function LoadingContainer() {
	return (
		<div className={styles['fullscreen-center']}>
			<Loader size={48} />
		</div>
	);
}
