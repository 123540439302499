import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { StatusIcon, Loader } from '@nearst/ui';
import Skeleton from '@components/Skeleton';

import { exportInventoryIssues, useInventoryStatus } from '@services/stock';
import { useShop } from '@services/ShopProvider';

import styles from './FeedDiagnostics.module.scss';
import { useCheckPermission } from '@services/auth/hooks';

const IssueDownloadBtn = ({ onDownload, issue }) => {
	const [preparingDownload, setPreparingDownload] = useState(false);
	const [downloadFailed, setDownloadFailed] = useState(false);

	const onClick = async () => {
		setPreparingDownload(true);

		try {
			await onDownload(issue.issueType);
		} catch (e) {
			setDownloadFailed(true);
		} finally {
			setPreparingDownload(false);
		}
	};
	if (preparingDownload) return <Loader size={14} data-testid={'feed-diagnostics-loading'} />;
	if (downloadFailed) return <p className={styles.downloadError}>Download unsuccessful</p>;
	return (
		<button onClick={onClick} className={styles.downloadBtn} data-testid={'feed-diagnostics-download'}>
			<ArrowDownTrayIcon className={styles.downloadIcon} />
		</button>
	);
};

const FeedDiagnosticsRow = ({ issue, index, rowSpan, onDownload }) => {
	return (
		<TableRow>
			{index === 0 && (
				<TableCell rowSpan={rowSpan} className={styles.issueCategory}>
					{issue.feedLevel === 'products' ? 'Product data' : 'Stock data'}
				</TableCell>
			)}
			<TableCell>
				<div className={styles.issueIconText}>
					<StatusIcon status={issue.severity} className={styles.statusIcon} />
					{issue.title}
				</div>
			</TableCell>
			<TableCell>{issue.issueCount.toLocaleString()}</TableCell>
			<TableCell>{issue.howToFix}</TableCell>
			{onDownload && (
				<TableCell align="center">
					<IssueDownloadBtn onDownload={onDownload} issue={issue} />
				</TableCell>
			)}
		</TableRow>
	);
};

export const FeedDiagnosticsTable = ({ onDownload, issues, isLoading, authorized }) => {
	if (!isLoading && issues.length === 0) return null;

	const productIssues = issues?.filter((issue) => issue.feedLevel === 'products');
	const stockIssues = issues?.filter((issue) => issue.feedLevel === 'stock');

	return (
		<>
			<h3 className={styles.sectionTitle}>Fix suggestions</h3>
			<p className={styles.sectionSubtitle}>
				We recommend fixing the issues in your feed to ensure all in-stock products are visible.
			</p>
			<div className={styles.desktopTableContainer}>
				<Table className={styles.desktopTable} role="table">
					<TableHead data-testid="desktop-table-head">
						<TableRow className={styles.columnHeaders}>
							<TableCell>Type</TableCell>
							<TableCell>Issue</TableCell>
							<TableCell>Items affected</TableCell>
							<TableCell>How to fix</TableCell>
							{authorized && <TableCell align="center">Download file</TableCell>}
						</TableRow>
					</TableHead>
					<TableBody data-testid="desktop-table-body">
						{isLoading ? (
							<TableRow>
								<TableCell colSpan={5}>
									<Skeleton width="100%" height={54} />
								</TableCell>
							</TableRow>
						) : (
							<>
								{productIssues.map((issue, index) => (
									<FeedDiagnosticsRow
										key={issue.type}
										issue={issue}
										index={index}
										rowSpan={productIssues.length}
										onDownload={authorized && onDownload}
									/>
								))}
								{stockIssues.map((issue, index) => (
									<FeedDiagnosticsRow
										key={issue.type}
										issue={issue}
										index={index}
										rowSpan={stockIssues.length}
										onDownload={authorized && onDownload}
									/>
								))}
							</>
						)}
					</TableBody>
				</Table>
			</div>
		</>
	);
};

const FeedDiagnostics = ({ channelId = 'nearst' }) => {
	const { shop } = useShop();

	const downloadIssueTypeOverview = async (issueType) => {
		const { url } = await exportInventoryIssues(shop.id, issueType);
		window.location.href = url;
	};

	const { data, isLoading } = useInventoryStatus(shop.id, channelId);
	const authorized = useCheckPermission('org:inventory:download');

	return <FeedDiagnosticsTable issues={data} isLoading={isLoading} onDownload={downloadIssueTypeOverview} authorized={authorized} />;
};

export default FeedDiagnostics;
