import React from 'react';
import { Protect } from '@clerk/clerk-react';

import DashboardContent from './Dashboard';
import AccessDenied from '../errors/AccessDenied';
import { useIsOnboarding } from '@components/OnboardingAccessControl';
import { RedirectWithShopId } from '@utils/RedirectWithShopId';

export const Dashboard = () => {
	const isOnboarding = useIsOnboarding();
	if (isOnboarding) {
		return <RedirectWithShopId to={'/onboarding/:shopId'} />;
	}
	return (
		<Protect permission="org:insights:read" fallback={<AccessDenied />}>
			<DashboardContent />
		</Protect>
	);
};
