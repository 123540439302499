import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';

import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/browser';

import AuthorizationTooltip from '@components/AuthorizationTooltip';
import { sendAnalytics } from '@lib/sendAnalytics';
import { UItils, Button, Stack, StatusIcon, Loader } from '@nearst/ui';
import { useCheckPermission } from '@services/auth/hooks';
import { saveFileToS3 } from '@services/shop';
import { customiseProduct, useStock } from '@services/stock';

import Image from '../Fields/Image';
import Text from '../Fields/Text';
import TextArea from '../Fields/TextArea';
import { isEmpty } from './formHelpers';

import styles from './EditForm.module.scss';

async function optimisticallyUpdate(editProduct, data) {
	await editProduct(data, {
		optimisticData: (existingData) => ({ ...existingData, ...data }),
		rollbackOnError: true
	});
}

const EditForm = () => {
	const { shopId, barcode } = useParams();
	const { data: stockItem } = useStock(barcode, shopId, { suspense: true });
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const { trigger: editProduct } = useSWRMutation(`stock_data-${shopId}-${barcode}`, async (_, { arg: modification }) =>
		customiseProduct(barcode, shopId, modification)
	);
	const defaultValues = useMemo(
		() => ({
			barcode,
			title: stockItem?.title || '',
			brand: stockItem?.brand || '',
			description: stockItem?.description || '',
			images: stockItem.images || [],
			image: undefined,
			imageSrc: stockItem?.images?.length ? stockItem.images[0] : undefined
		}),
		[barcode, stockItem]
	);
	const authorized = useCheckPermission('org:inventory:edit');
	const methods = useForm({
		defaultValues,
		// validate on change
		mode: 'all'
	});
	const {
		formState: { errors, isValid, isSubmitting, isDirty, isSubmitSuccessful },
		clearErrors,
		handleSubmit,
		reset,
		getValues,
		setError,
		register
	} = methods;

	const onSubmit = async (data) => {
		setErrorMessage('');
		setLoading(true);
		if (!authorized) {
			setLoading(false);
			throw Error('Not authorised');
		}
		if (Object.entries(errors).length || isEmpty(data)) {
			console.error(errors);
			setLoading(false);
			throw Error('Errors in data submitted');
		}
		try {
			// only upload if new image input (in which case it will be a File and not undefined)
			if (data.image) {
				// TODO: better logic once we implement multiple image uploads
				const images = stockItem?.images || [];
				const imageUrl = await saveFileToS3(shopId, data.image);
				images.unshift(imageUrl);
				data.images = images;
			}

			try {
				await optimisticallyUpdate(editProduct, data);
			} catch (e) {
				console.error(e);
				// retry once
				await optimisticallyUpdate(editProduct, data);
			}
			sendAnalytics('Custom Product Form', 'Submit edit', shopId);
			mixpanel.track('Custom product edited', { distinct_id: shopId, barcode, ...data });

			// Reset form meta state (e.g. validation etc) to reset UI
			reset(data, { keepValues: true });
		} catch (error) {
			let message = 'Error submitting product edit form.';
			setErrorMessage('Sorry, something went wrong updating this product. Please try again or contact support.');
			// Joi validation throws in a specific way
			if (error.response?.data?.error?.message === 'Validation failed.') {
				message = 'Error submitting product edit form (validation failed server-side).';
				setErrorMessage('Additional data required to update this product.');
				// manually set validation errors on the form
				error.response.data.error.details.forEach((err) => {
					setError(err.context.key, { type: 'required', message: 'Required' });
				});
			}
			console.error(message);
			Sentry.captureException(error);
		} finally {
			setLoading(false);
		}
	};
	const onError = (errors) => {
		// this scenario only happens if there's a validation fail
		// no need to message - validation errors should pop up
		console.error(errors);
	};
	const handleSave = async (e) => {
		e.preventDefault();
		handleSubmit(onSubmit, onError)(e);
	};
	const handleCancel = (e) => {
		e.preventDefault();
		// wipe clean - reset back to defaults
		reset();
		setErrorMessage('');
		clearErrors();
	};

	const canSubmit = isDirty && isValid && !isSubmitting;
	const canCancel = isDirty && !isSubmitting;
	const isValidationError = (Object.entries(errors).length || isEmpty(getValues())) && !stockItem.isGtin;
	const isSuccessFul = !isSubmitting && isSubmitSuccessful && !errorMessage;

	const Message = () => {
		// placeholder so UI doesn't jump around when message appears
		let message = <p className={styles.message}> </p>;
		if (isValidationError) {
			message = (
				<p className={UItils.classnames(styles.message, styles.validation)}>
					<StatusIcon status="error" />
					Additional data required to update this product.
				</p>
			);
		} else if (errorMessage) {
			message = (
				<p className={UItils.classnames(styles.message, styles.validation)}>
					<StatusIcon status="error" /> {errorMessage}
				</p>
			);
		} else if (isSuccessFul && isSubmitSuccessful && !isDirty) {
			message = (
				<p className={styles.message}>
					<StatusIcon status="active" /> Your changes have been applied.
				</p>
			);
		}
		return message;
	};

	return (
		<>
			<FormProvider {...methods}>
				<form className={styles.form} onSubmit={handleSave}>
					<Stack>
						<div className={styles.container}>
							<Stack className={styles.leftStack} space="0.5rem">
								{stockItem.isGtin ? (
									<p className={styles.message}>Data is automatically supplied for this barcode in your channels.</p>
								) : null}
								<Text name="title" required disabled={!authorized} {...register('title')} />
								<Text name="brand" required disabled={!authorized} {...register('brand')} />
							</Stack>
							<Image className={styles.rightStack} name="imageSrc" required disabled={!authorized} />
							<TextArea
								className={styles.fullWidth}
								name="description"
								required
								disabled={!authorized}
								{...register('description')}
							/>
						</div>
						<div className={styles.actionsContainer}>
							<AuthorizationTooltip authorized={authorized}>
								<Button disabled={!authorized || !canSubmit} type="submit" primary>
									Save
								</Button>
							</AuthorizationTooltip>
							<Button disabled={!authorized || !canCancel} onClick={handleCancel}>
								Cancel
							</Button>
							{loading && <Loader size={16} />}
							<Message />
						</div>
					</Stack>
				</form>
			</FormProvider>
		</>
	);
};

export default EditForm;
