import React, { useState } from 'react';
import { ArrowDownTrayIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';

import { Button, Card, Cluster, Loader } from '@nearst/ui';

import { getChannelFeedDownloadLink } from '@services/inventory';


import styles from './ChannelIssues.module.scss';

const FeedExport = ({ channelDefinition, shopId }) => {
	const [url, setUrl] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const handleClick = async () => {
		setLoading(true);
		setError(false);
		try {
			if (url) {
				window.location.href = url;
			} else {
				const s3Url = await getChannelFeedDownloadLink(channelDefinition.id, shopId);
				setUrl(s3Url);
				window.location.href = s3Url;
			}
		} catch (e) {
			setError(true);
		} finally {
			setLoading(false);
		}
	};
	return (
		<Card
			title="Feed export"
			description={`Export a file containing all fields required for ${channelDefinition.title}. The file will contain the latest stock availability for each product.`}
		>
			<Cluster>
				<Button disabled={loading} onClick={handleClick} primary>
					Download{' '}
					{loading ? (
						<Loader className={styles.loader} size={14} />
					) : error ? (
						<ExclamationCircleIcon height={16} />
					) : (
						<ArrowDownTrayIcon height={16} />
					)}
				</Button>
				{error && <p className={styles.error}>Error creating feed.</p>}
			</Cluster>
		</Card>
	);
};

export default FeedExport;
