import { useMemo } from 'react';
import useSWR from 'swr';

import myShopClient from './nearlive';

/**
 * List current add-on for a shop.
 *
 * @param {object} options
 * @param {string} options.id Shop ID
 * @param {string} [options.status] Optional status to filter by: 'historic', 'live' or 'upcoming'
 * @returns {Promise<object>}
 */
export async function listPlans({ id, status = null }) {
	const client = await myShopClient();
	const query = new URLSearchParams({ status });
	const response = await client.get(`/api/channels/local-ads/plan/${id}?${query.toString()}`);
	if (!response.data) return {};
	return response.data;
}

/**
 * List all historic, live and upcoming plans for a shop.
 *
 * @param {object} options
 * @param {string} [options.id] Shop ID
 * @returns {Promise<Array<object>>}
 */
export async function listAllPlans({ id = null } = {}) {
	const client = await myShopClient();
	const response = await client.get(`/api/channels/local-ads/all-plans${id ? `/${id}` : ''}`);
	if (!response.data) return [];
	return response.data;
}

export function usePlansForShopIds(shopIds) {
	const { data: allPlans } = useSWR(`local-ads-all-plans`, () => listAllPlans());
	const plans = useMemo(() => allPlans?.filter((plan) => shopIds.includes(plan.shop)) || [], [allPlans, shopIds]);

	return plans;
}

/**
 * Set a new upcoming add-on plan for a shop.
 *
 * @param {object} options
 * @param {string} options.id Shop ID
 * @param {number} options.budget Budget for the plan
 * @param {string} [options.name] Shop name
 */
export async function createPlan({ id, budget }) {
	const client = await myShopClient();
	await client.put(`/api/channels/local-ads/plan/${id}`, { budget });
}
